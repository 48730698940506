import { FC } from 'react';

import { useAuth } from '../../hooks/useAuth';
import { useImpersonateUser } from '../../hooks/useImpersonateUser';

import { Modal } from '../Modal/Modal';
import { ImpersonationModalContent } from './ImpersonationModalContent';
import content from './content';

export const ImpersonationModal: FC = () => {
    const { isAdminUser } = useAuth();
    const {
        modalOpen,
        toggleModal,
        isImpersonating,
        userName,
        startImpersonation,
        stopImpersonation,
        error,
    } = useImpersonateUser();

    if (!isAdminUser) {
        return null;
    }

    const onClose = () => toggleModal(false);

    const modalContent = (
        <ImpersonationModalContent
            isImpersonating={isImpersonating}
            userName={userName}
            onFormSubmit={startImpersonation}
            onStopImpersonating={stopImpersonation}
            serverError={error}
        />
    );

    return (
        <Modal
            open={modalOpen}
            onClose={onClose}
            content={modalContent}
            title={content.title}
            variant="large"
        />
    );
};
