import { FC, ReactNode, useMemo } from 'react';

import {
    AccordionItemButton,
    Accordion as ReactAccordion,
    AccordionItemHeading as ReactAccordionHeading,
    AccordionItem as ReactAccordionItem,
    AccordionItemPanel as ReactAccordionItemPanel,
} from 'react-accessible-accordion';

import CircleMinusIcon from '../Icons/CircleMinusIcon';
import CirclePlusIcon from '../Icons/CirclePlusIcon';
import './Accordion.scss';

type UUID = string | number;

type AccordionProps = {
    className?: string;
    preExpanded?: UUID[];
    allowMultipleExpanded?: boolean;
    allowZeroExpanded?: boolean;
    onChange?(args: string[]): void;
    children?: ReactNode;
};

export const Accordion: FC<AccordionProps> = ({
    className,
    preExpanded,
    allowMultipleExpanded,
    allowZeroExpanded,
    onChange,
    children,
}) => {
    const classes = useMemo(() => {
        let base = 'chw-accordion';
        if (className) {
            base = `${base} ${className}`;
        }
        return base;
    }, [className]);

    return (
        <ReactAccordion
            className={classes}
            preExpanded={preExpanded}
            allowZeroExpanded={allowZeroExpanded}
            allowMultipleExpanded={allowMultipleExpanded}
            onChange={onChange}
        >
            {children}
        </ReactAccordion>
    );
};

type AccordionItemProps = {
    uuid?: UUID;
    className?: string;
    activeClassName?: string;
    dangerouslySetExpanded?: boolean;
    children?: ReactNode;
};

export const AccordionItem: FC<AccordionItemProps> = ({
    children,
    uuid,
    className,
    activeClassName,
    dangerouslySetExpanded,
}) => {
    const classes = useMemo(() => {
        let base = 'chw-accordion__item';
        if (className) {
            base = `${base} ${className}`;
        }
        return base;
    }, [className]);

    return (
        <ReactAccordionItem
            uuid={uuid}
            className={classes}
            activeClassName={activeClassName}
            dangerouslySetExpanded={dangerouslySetExpanded}
        >
            {children}
        </ReactAccordionItem>
    );
};

type AccordionHeadingProps = {
    className?: string;
    children?: ReactNode;
};

export const AccordionItemHeading: FC<AccordionHeadingProps> = ({
    children,
    className,
}) => {
    const classes = useMemo(() => {
        let base = 'chw-accordion__item__heading';
        if (className) {
            base = `${base} ${className}`;
        }
        return base;
    }, [className]);

    return (
        <ReactAccordionHeading className={classes} aria-level={2}>
            <AccordionItemButton
                className="chw-accordion__item__heading__button"
                data-testid="accordion-heading"
            >
                {children}
                <div className="chw-accordion-icons">
                    <CirclePlusIcon />
                    <CircleMinusIcon />
                </div>
            </AccordionItemButton>
        </ReactAccordionHeading>
    );
};

type AccordionPanelPros = {
    className?: string;
    children?: ReactNode;
};

export const AccordionItemPanel: FC<AccordionPanelPros> = ({
    children,
    className,
}) => {
    const classes = useMemo(() => {
        let base = 'chw-accordion__panel';
        if (className) {
            base = `${base} ${className}`;
        }
        return base;
    }, [className]);

    return (
        <ReactAccordionItemPanel className={classes}>
            {children}
        </ReactAccordionItemPanel>
    );
};
