/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArticleSearchRequest } from '../models/ArticleSearchRequest';
import type { ArticleViewModel } from '../models/ArticleViewModel';
import type { NotificationsResultViewModel } from '../models/NotificationsResultViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationsService {

    /**
     * @param page
     * @param requestBody
     * @returns NotificationsResultViewModel Success
     * @throws ApiError
     */
    public static postApiNotifications(
        page?: number,
        requestBody?: ArticleSearchRequest,
    ): CancelablePromise<NotificationsResultViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Notifications',
            query: {
                'page': page,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param notificationId
     * @returns ArticleViewModel Success
     * @throws ApiError
     */
    public static article(
        notificationId: number,
    ): CancelablePromise<ArticleViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Notifications/article/{notificationId}',
            path: {
                'notificationId': notificationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns NotificationsResultViewModel Success
     * @throws ApiError
     */
    public static alerts(): CancelablePromise<NotificationsResultViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Notifications/Alerts',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}