import { FC } from 'react';

import dayjs from 'dayjs';

import { useDeviceContext } from '../../hooks/useDeviceContext';

import { Button, ButtonProps } from '../Button/Button';
import { InfoBar } from '../InfoBar/InfoBar';
import content from './content';

export type SubscriptionExpiryBarProps = {
    componentId: string;
    show: boolean;
    handleConfirmationClick: () => void;
    handleCancelClick: () => void;
    handleCloseButtonClick: () => void;
    expiryDate: string;
};

const formattedTitle = (str: string, date: string) => {
    const formattedDate = dayjs(date).format('MMMM YYYY');
    return str.replace('{0}', formattedDate);
};

export const SubscriptionExpiryBar: FC<SubscriptionExpiryBarProps> = ({
    componentId,
    show,
    handleConfirmationClick,
    handleCancelClick,
    handleCloseButtonClick,
    expiryDate,
}) => {
    const { windowSize } = useDeviceContext();
    const title = formattedTitle(content.expiryText, expiryDate);

    const buttonSize: ButtonProps['size'] =
        windowSize?.width && windowSize.width < 640 ? 'small' : 'default';

    return (
        <InfoBar
            title={title}
            actions={
                <>
                    <Button
                        variant="standard"
                        onClick={handleConfirmationClick}
                        type="button"
                        id={`${componentId}-confirmation-button`}
                        testId={`${componentId}-confirmation-button`}
                        size={buttonSize}
                    >
                        {content.confirmBtn}
                    </Button>
                    <Button
                        variant="outline"
                        onClick={handleCancelClick}
                        type="button"
                        id={`${componentId}-cancel-button`}
                        testId={`${componentId}-cancel-button`}
                        size={buttonSize}
                    >
                        {content.cancelBtn}
                    </Button>
                </>
            }
            show={show}
            customClass="xl:pl-[3.75rem] relative z-40"
            contentAlignment="left"
            onDismissClick={handleCloseButtonClick}
            transitionFrom="top"
        />
    );
};
