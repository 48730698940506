import {
    LinkClickTrackingPlugin,
    trackLinkClick,
} from '@snowplow/browser-plugin-link-click-tracking';
import {
    SelfDescribingJson,
    addGlobalContexts,
    disableActivityTracking,
    enableActivityTracking,
    newTracker,
    setCustomUrl,
    setReferrerUrl,
    setUserId,
    trackPageView,
    trackSelfDescribingEvent,
} from '@snowplow/browser-tracker';
import { matchPath } from 'react-router-dom';

import { ContentFilter, Document, DocumentViewModel } from '../services';

import { Pages } from '../domain/Pages';

import { getConfigValue } from '../util/config';
import { getChosenFiltersFlattened } from '../util/filterUtils';

import { UserData } from '../hooks/useAuth';

import {
    getArticleFiltersEntitySchema,
    getArticleToolEventSchema,
    getBookmarkEventSchema,
    getDocumentDownloadEventSchema,
    getDocumentEntitySchema,
    getFilterArticleListingEventSchema,
    getGenericControlEventSchema,
    getInlineDocumentPagingEventSchema,
    getLinkClickArticleListingEntitySchema,
    getPriceTableDownloadEventSchema,
    getPriceTableEntitySchema,
    getPromotionClickEventSchema,
    getSaveSelectionEventSchema,
    getSiteNavigationSchema,
    getUserEntitySchema,
} from './schema-selectors';
import {
    ArticleFiltersEntity,
    ArticleToolEvent,
    BookmarkEvent,
    DocumentEntity,
    GenericControlEvent,
    InlineDocumentEvent,
    PriceTableEntity,
    PromotionClickEvent,
    SaveSelectionEvent,
    SnowplowArticleContext,
    TrackPageViewEvent,
} from './types';

const snowplowCollectorUrl = getConfigValue('REACT_APP_SNOWPLOW_COLLECTOR_URL');
const snowplowAppId = getConfigValue('REACT_APP_SNOWPLOW_APP_ID');
let snowplowEnabled = getConfigValue('REACT_APP_FF_ENABLE_SNOWPLOW') === 'true';

const initialize = (userSchemaData: UserData) => {
    if (!snowplowEnabled) {
        return console.warn('snowplow not enabled');
    }

    // Create a new tracker instance
    newTracker('snowplow', snowplowCollectorUrl, {
        appId: snowplowAppId,
        platform: 'web',
        eventMethod: 'beacon',
        plugins: [LinkClickTrackingPlugin()],
    });

    // Use event delegation to bind click event to all anchors
    addGlobalListener();

    // Sets the userId property for all events
    setUserId(userSchemaData.sales_id ?? userSchemaData.cruonline_user_id);

    // Add user entity to all tracking events
    addGlobalUserEntity(userSchemaData);
};

const pageView = ({ customUrl, ...event }: TrackPageViewEvent) => {
    if (!snowplowEnabled) {
        return;
    }

    // override the page url with custom value so we can record fake pageviews for error pages
    if (customUrl) {
        setCustomUrl(customUrl);
    }

    enableActivityTracking({
        minimumVisitLength: 10,
        heartbeatDelay: 10,
    });

    trackPageView(event);

    // set a custom referrer if customUrl is defined
    // this should be set AFTER trackPageView
    if (customUrl) {
        setReferrerUrl(customUrl);
    }
};

// Create a user context entity and add it to global context
const addGlobalUserEntity = (userData: UserData) => {
    const hasValidUser =
        userData.sales_id ?? userData.tracking_id ?? userData.cruonline_user_id;

    const userEntity = getUserEntitySchema({
        tracking_id: userData.tracking_id,
        cruonline_user_id: userData.cruonline_user_id,
        a_codes: userData.a_codes,
        subscription_tier: userData.subscription_tier,
    });

    if (hasValidUser) {
        addGlobalContexts([userEntity]);
    }
};

const linkClickContext = (element: HTMLAnchorElement) => {
    const group = element.getAttribute(
        'data-snowplow-group'
    ) as SnowplowArticleContext['group'];

    // For links that need a custom context, get it
    if (group === 'article_listing') {
        return getLinkClickArticleListingEntitySchema({
            component_name:
                element.getAttribute('data-snowplow-subgroup') ?? '',
        });
    } else if (group === 'site_navigation') {
        return getSiteNavigationSchema({
            site_navigation: true,
        });
    }
    return null;
};

const trackLink = (anchor: HTMLAnchorElement) => {
    if (!snowplowEnabled) {
        return;
    }

    // filter out known unwanted link click events that are handled otherwise:
    if (
        // Price Detail download CSV
        anchor.getAttribute('download')?.endsWith('Prices.csv') &&
        matchPath(
            {
                path: Pages.priceDetail,
                end: false,
            },
            location.pathname
        )
    ) {
        return;
    } else if (
        // - Product Tour Triggered by Campaign clickthrough link
        anchor.getAttribute('data-snowplow-group') === 'product_tours'
    ) {
        trackPromotionClick({
            interaction_type: 'banner_click',
            campaign_name: anchor.getAttribute('data-snowplow-subgroup') ?? '',
        });
        return;
    }

    const href = anchor.getAttribute('href') ?? '';
    const context = linkClickContext(anchor);
    if (context) {
        trackLinkClick({ targetUrl: href, context: [context] });
    } else {
        trackLinkClick({ targetUrl: href });
    }
};
const addGlobalListener = () => {
    document.onclick = function (e) {
        const elemTarget = e.target && (e.target as Element);
        const target = elemTarget?.closest('a:not([data-routerlink])') as
            | HTMLAnchorElement
            | undefined;
        if (target) {
            trackLink(target);
        }
    };
};

const isDocumentViewModel = (
    doc: DocumentViewModel | Document
): doc is DocumentViewModel => {
    return 'aCodes' in (doc as DocumentViewModel);
};

const trackDownload = (payload: DocumentViewModel | Document) => {
    if (!snowplowEnabled) {
        return;
    }

    const hasAdditionalProperties = isDocumentViewModel(payload);

    const event = getDocumentDownloadEventSchema({
        document_id: payload.id,
    });
    const documentEntity = getDocumentEntitySchema({
        document_id: payload.id,
        title: payload.name,
        filename: payload.url,
        file_type_name: payload.fileType,
        upload_type: hasAdditionalProperties ? payload.uploadType : null,
        a_codes: hasAdditionalProperties ? payload.aCodes : null,
        file_extension: hasAdditionalProperties ? payload.fileExtension : null,
    });

    trackSelfDescribingEvent({
        event,
        context: [documentEntity],
    });
};

const trackPriceTableDownload = (payload: PriceTableEntity) => {
    if (!snowplowEnabled) {
        return;
    }

    const event = getPriceTableDownloadEventSchema({
        table_id: payload.data_table_id,
    });
    const priceTableEntity = getPriceTableEntitySchema(payload);

    trackSelfDescribingEvent({
        event,
        context: [priceTableEntity],
    });
};

const trackInlineDocumentPaging = ({
    eventData,
    documentData,
}: {
    eventData: InlineDocumentEvent;
    documentData: DocumentEntity;
}) => {
    if (!snowplowEnabled) {
        return;
    }

    const event = getInlineDocumentPagingEventSchema(eventData);
    const documentEntity = getDocumentEntitySchema(documentData);

    trackSelfDescribingEvent({
        event,
        context: [documentEntity],
    });
};

const trackArticleToolEvent = (payload: ArticleToolEvent) => {
    if (!snowplowEnabled) {
        return;
    }

    const event = getArticleToolEventSchema(payload);

    trackSelfDescribingEvent({
        event,
    });
};

const trackArticleFilters = (
    filterLocation:
        | 'analysis_listing'
        | 'notifications_listing'
        | 'search_results',
    selectedFilters: ContentFilter[]
) => {
    if (!snowplowEnabled) {
        return;
    }

    const event = getFilterArticleListingEventSchema({
        filter_location: filterLocation,
    });

    // get the selected date range
    const publishedDateRange =
        selectedFilters.find((filter) => filter.id === 'filter-published')
            ?.entries || [];
    const publishedStartDate =
        publishedDateRange.find(
            (dateFilter) => dateFilter.id === 'published-start'
        )?.value || null;
    const publishedEndDate =
        publishedDateRange.find(
            (dateFilter) => dateFilter.id === 'published-end'
        )?.value || null;

    // For each filter menu, transform deeply nested filters into a flat array
    const { filters } = getChosenFiltersFlattened(selectedFilters);

    // convert selected filters into an object
    // each key name represents a filter menu
    // each value represents an array consisting of selected filter ids
    const filtersObj: Record<string, string[]> = Object.fromEntries(
        filters.map((filter) => [
            filter.id,
            filter.entries?.map((entry) => entry.id),
        ])
    );

    // conditionally add selected filter ids and published date ranges to ArticleFiltersEntity
    const entityData: ArticleFiltersEntity = {
        commodity_tags: filtersObj['filter-commodity'] ?? null,
        topic_tags: filtersObj['filter-topic'] ?? null,
        location_tags: filtersObj['filter-region'] ?? null,
        content_type: filtersObj['filter-type'] ?? null,
        published_start_date: publishedStartDate,
        published_end_date: publishedEndDate,
    };

    const articleFiltersEntity = getArticleFiltersEntitySchema(entityData);

    trackSelfDescribingEvent({
        event,
        context: [articleFiltersEntity],
    });
};

const trackPromotionClick = (payload: PromotionClickEvent) => {
    if (!snowplowEnabled) {
        return;
    }

    const event = getPromotionClickEventSchema(payload);

    trackSelfDescribingEvent({
        event,
    });
};

const trackBookmark = (
    payload: BookmarkEvent,
    context?: SelfDescribingJson<Record<string, unknown>>[]
) => {
    if (!snowplowEnabled) {
        return;
    }

    const event = getBookmarkEventSchema(payload);

    trackSelfDescribingEvent({
        event,
        context,
    });
};

const trackSaveSelection = (payload: SaveSelectionEvent) => {
    if (!snowplowEnabled) {
        return;
    }

    const event = getSaveSelectionEventSchema(payload);

    trackSelfDescribingEvent({
        event,
    });
};

const trackGenericControl = (payload: GenericControlEvent) => {
    if (!snowplowEnabled) {
        return;
    }

    const event = getGenericControlEventSchema(payload);

    trackSelfDescribingEvent({
        event,
    });
};

const disableTracking = () => {
    snowplowEnabled = false;

    disableActivityTracking();
};

const enableTracking = () => {
    snowplowEnabled = true;

    enableActivityTracking({
        minimumVisitLength: 10,
        heartbeatDelay: 10,
    });
};

export default {
    initialize,
    pageView,
    trackLink,
    trackDownload,
    trackPriceTableDownload,
    trackInlineDocumentPaging,
    trackArticleToolEvent,
    trackArticleFilters,
    trackPromotionClick,
    trackBookmark,
    trackSaveSelection,
    trackGenericControl,
    enableTracking,
    disableTracking,
};
