import { FC } from 'react';

import { useAuth } from '../../../../../hooks/useAuth';
import { useImpersonateUser } from '../../../../../hooks/useImpersonateUser';

import {
    HeaderSubMenuItem,
    HeaderSubMenuItemProps,
} from '../HeaderSubMenuItem/HeaderSubMenuItem';

export const ImpersonateUserButton: FC<HeaderSubMenuItemProps> = (props) => {
    const { isAdminUser } = useAuth();
    const { toggleModal } = useImpersonateUser();

    if (!isAdminUser) {
        return null;
    }

    const onClick = () => toggleModal(true);

    return (
        <HeaderSubMenuItem
            {...props}
            testId="impersonate-user-btn"
            onClick={onClick}
        />
    );
};
