import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { GenericPage, GenericPageService } from '../../services';

import { QueryKeys } from '../../domain/QueryKeys';

import { useAuth } from '../useAuth';

export function useGenericPage(pageName: string): UseQueryResult<GenericPage> {
    const { makeRequest } = useAuth();
    return useQuery(
        [QueryKeys.useGenericPage, pageName],
        () =>
            makeRequest<GenericPage>(() =>
                GenericPageService.getGenericPage(pageName)
            ),
        {
            useErrorBoundary: true,
        }
    );
}
