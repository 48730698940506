import React, { FC, ReactNode, useState } from 'react';

import classNames from 'classnames';

import { HeaderItemModel } from '../../../../../services';

import { useDeviceContext } from '../../../../../hooks/useDeviceContext';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
} from '../../../../Accordion/Accordion';
import ChevronDownIcon from '../../../../Icons/ChevronDownIcon';
import { Link } from '../../../../Link/Link';
import { PopoverComponent } from '../../../../Popover/Popover';
import { HeaderSubMenu } from '../HeaderSubMenu/HeaderSubMenu';
import './HeaderLink.scss';

type HeaderLinkProps = {
    index: number;
    isActive: boolean;
    children?: ReactNode;
} & Partial<HeaderItemModel>;

interface SubMenuWrapperProps extends HeaderLinkProps {
    isActive: boolean;
}

const SubMenuWrapper: FC<SubMenuWrapperProps> = (props) => {
    const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
    const { smallDevice } = useDeviceContext();

    if (smallDevice) {
        return (
            <Accordion
                allowZeroExpanded
                allowMultipleExpanded
                className="header-nav-item"
            >
                <AccordionItem>
                    <AccordionItemHeading className="header-accordion-category">
                        {props.children}
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <HeaderSubMenu {...props} />
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        );
    }

    const headerLinkClassName = classNames('header-nav-item__button', {
        current: props.isActive,
    });

    const headerNavItemClassName = classNames('header-nav-item', {
        'header-nav-item--sub-menu-active': isSubMenuVisible,
    });

    const onNavItemEnter = () => {
        setIsSubMenuVisible(true);
    };

    const onNavItemLeave = () => {
        setIsSubMenuVisible(false);
    };

    const onHeaderLinkClick = () => {
        setIsSubMenuVisible(true);
    };

    return (
        <div
            className={headerNavItemClassName}
            onMouseEnter={onNavItemEnter}
            onMouseLeave={onNavItemLeave}
        >
            <button className={headerLinkClassName} onClick={onHeaderLinkClick}>
                <span className="header-nav-item__title">{props.children}</span>
                <ChevronDownIcon className="header-nav-item__button-icon" />
            </button>
            <HeaderSubMenu {...props} />
        </div>
    );
};

export const HeaderLink: React.FC<HeaderLinkProps> = (props) => {
    if (!props.isEnabled) {
        return (
            <PopoverComponent
                testId={`header-link-${props.index}`}
                buttonText={props.children}
                panelContent={
                    <p>
                        This section is currently not part of your CRU
                        subscription. To add this to your subscription, please
                        contact{' '}
                        <a href="mailto:customer.services@crugroup.com">
                            customer.services@crugroup.com
                        </a>
                    </p>
                }
                className="header-link--inaccessible"
            />
        );
    }

    if (!props.subMenu) {
        const navLinkClasses = classNames('header-link', {
            current: props.isActive,
        });
        return (
            <Link
                renderAs="NavLink"
                className={navLinkClasses}
                key={props.index}
                to={props.link || ''}
                data-snowplow-group="site_navigation"
            >
                {props.children}
            </Link>
        );
    }

    return <SubMenuWrapper {...props} isActive={props.isActive} />;
};
