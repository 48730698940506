// Blues
const azure = {
    100: '#AADAFE',
    200: '#85C9FE',
    300: '#55B4FD',
    400: '#30A4FD', // Main
    500: '#2785CE',
    600: '#2171AE',
    700: '#18527F',
};

// Purples
const ceasiumData = {
    100: '#E2C7FC',
    200: '#D4ABFB',
    300: '#CB98FA',
    400: '#C185F9', // Main
    500: '#A16FCF',
    600: '#8159A6',
    700: '#61437D',
};

// Pinks
const lithiumInsight = {
    100: '#F3BBE5',
    200: '#EFA5DD',
    300: '#E983D0',
    400: '#E56CC7', // Main
    500: '#BF5AA6',
    600: '#863F74',
    700: '#5F2D53',
};

// Salmons
const strontiumStrategy = {
    100: '#F8BDBA',
    200: '#F6A8A3',
    300: '#F28781',
    400: '#F0716A', // Main
    500: '#C85E58',
    600: '#8C423E',
    700: '#642F2C',
};

// Greens
const copperCommunities = {
    100: '#AADCD9',
    200: '#7FCBC6',
    300: '#62C0BA',
    400: '#46B4AD', // Main
    500: '#3A9690',
    600: '#2F7873',
    700: '#235A57',
};

const aubergine = {
    100: '#5D3F6D',
    200: '#4D325B',
    300: '#391E48',
    400: '#260936', // Main
};

const blueBlack = {
    100: '#213453',
    200: '#1D2C43',
    300: '#101D31',
    400: '#000E25', // Main
};

export const variations = {
    aubergine,
    blueBlack,
    azure,
    ceasiumData,
    lithiumInsight,
    strontiumStrategy,
    copperCommunities,
};
