import { FC, useEffect, useMemo } from 'react';

import { LiveMessage } from 'react-aria-live';
import { Helmet } from 'react-helmet-async';

interface PageMetaProps {
    title: string;
}

export const PageMeta: FC<PageMetaProps> = ({ title }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const screenReaderMessage = useMemo(() => {
        if (!title) {
            return null;
        }
        return (
            <LiveMessage
                message={`${title} has loaded`}
                aria-live="assertive"
            />
        );
    }, [title]);

    return (
        <>
            {screenReaderMessage}
            <Helmet>
                <title>{title}</title>
                <link rel="canonical" href="" />
            </Helmet>
        </>
    );
};
