/* eslint-disable sonarjs/no-duplicate-string */
type ScrollOptions = {
    isDesktop?: boolean;
};

export const disableScroll = (params: ScrollOptions = {}): void => {
    const classes = params.isDesktop
        ? ['overflow-hidden']
        : ['overflow-hidden', 'xl:overflow-auto'];
    document.body.classList.add(...classes);
};

export const enableScroll = (): void => {
    document.body.classList.remove('overflow-hidden', 'xl:overflow-auto');
};
