import { FC, ReactNode } from 'react';

import { Brand } from '../../../components/Brand/Brand';
import footerContent from '../../../components/Layout/Footer/FooterContent';
import { PageMeta } from '../../../components/Layout/PageMeta';
import { PageViewTracker } from '../../../components/PageViewTracker/PageViewTracker';

import authBackground from '../../../resources/images/auth/background.jpg';
import authTagline from '../../../resources/images/auth/tagline.svg';

import { TrackPageViewEvent } from '../../../snowplow-tracking/types';
import { Link } from '../../Link/Link';

export type AuthFlowLayoutProps = {
    pageViewData?: TrackPageViewEvent;
    heading: string;
    textBlock: ReactNode;
    actionBarContent?: ReactNode;
    footerLinks?: { text: string; href?: string; path?: string }[];
};

export const AuthFlowLayout: FC<AuthFlowLayoutProps> = ({
    pageViewData,
    heading,
    textBlock,
    actionBarContent,
    footerLinks = [],
}) => (
    <div className="flex min-h-screen flex-col bg-gradient-to-t from-brand-aubergine to-[#020566]">
        <PageMeta title={pageViewData?.title ?? ''} />
        <PageViewTracker pageViewData={{ title: pageViewData?.title }} />
        <div className="flex flex-1">
            <div className="w-full lg:grid lg:grid-cols-2">
                <div
                    className="hidden h-full flex-col justify-between bg-cover bg-center p-12 pb-20 lg:flex"
                    style={{ backgroundImage: `url(${authBackground})` }}
                >
                    <Brand size="78px" />
                    <img
                        src={authTagline}
                        alt="Independent expert intelligence"
                        className="max-w-sm"
                    />
                </div>
                <main className="flex min-h-full flex-col items-center px-8 py-12 text-brand-white lg:justify-center">
                    <div className="w-full space-y-6 sm:w-[27.5rem]">
                        <div className="space-y-8">
                            <Brand size="78px" className="lg:hidden" />
                            {heading && (
                                <h1 className="font-cormorant text-[3.375rem] leading-tight text-brand-grey-20">
                                    {heading}
                                </h1>
                            )}
                        </div>
                        <div className="space-y-4">{textBlock}</div>
                        {actionBarContent && (
                            <div className="flex flex-col items-start space-y-4">
                                {actionBarContent}
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </div>
        <footer className="flex flex-col gap-4 bg-brand-grey-30 p-5 text-sm text-brand-blue-black-100 lg:flex-row lg:justify-between xl:px-20 xl:py-5">
            {footerLinks && (
                <ul className="mr-4 flex flex-wrap gap-4 lg:gap-8">
                    {footerLinks.map((item, i) => (
                        <li key={i}>
                            {item.path && (
                                <Link to={item.path}>{item.text}</Link>
                            )}
                            {item.href && (
                                <a
                                    href={item.href}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {item.text}
                                </a>
                            )}
                        </li>
                    ))}
                </ul>
            )}
            <span className="flex-none">{footerContent.copyright}</span>
        </footer>
    </div>
);
