import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { Pages } from '../../../../../domain/Pages';

import { useProductToursContext } from '../../../../../hooks/useProductToursContext';

import {
    HeaderSubMenuItem,
    HeaderSubMenuItemProps,
} from '../HeaderSubMenuItem/HeaderSubMenuItem';
import './ProductTourButton.scss';

export const ProductTourButton: FC<HeaderSubMenuItemProps> = (props) => {
    const navigate = useNavigate();
    const { startTour } = useProductToursContext();

    const onClick = () => {
        navigate({
            pathname: Pages.analysis,
        });
        startTour('welcome');
    };

    return (
        <HeaderSubMenuItem
            customClass="product-tour-button"
            customLinkClass="gtm-product-tour-button"
            {...props}
            onClick={onClick}
        />
    );
};
