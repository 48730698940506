import { FC } from 'react';

import { useAuth } from '../../../../../hooks/useAuth';

import {
    HeaderSubMenuItem,
    HeaderSubMenuItemProps,
} from '../HeaderSubMenuItem/HeaderSubMenuItem';

export const LogoutButton: FC<HeaderSubMenuItemProps> = (props) => {
    const { destroySession } = useAuth();

    const onClick = () => {
        destroySession();
    };

    return <HeaderSubMenuItem {...props} onClick={onClick} />;
};
