import React from 'react';

import { getConfigValue } from '../../../util/config';

import { Brand } from '../../Brand/Brand';
import { ContactUs } from '../../ContactUs/ContactUs';
import { Feedback } from '../../FeedBack/Feedback';
import { Link } from '../../Link/Link';
import { ScheduleTourButton } from '../../ScheduleTourButton/ScheduleTourButton';
import './Footer.scss';
import footerContent from './FooterContent';

const tourUrl = getConfigValue('REACT_APP_TOUR_BOOKING_URL');

export const Footer: React.FC = () => {
    return (
        <footer id="chw-footer" className="footer">
            <div className="footer__cta-bar">
                <div className="footer__content">
                    <Brand size="68px" loading="lazy" />
                    <div className="footer__cta-bar-content">
                        <ScheduleTourButton tourUrl={tourUrl} />
                        <ContactUs />
                        <Feedback />
                    </div>
                </div>
            </div>
            <div className="footer__links">
                <div className="footer__content">
                    <ul className="footer__links-list">
                        {footerContent.links.map((link, i) => (
                            <li key={i}>
                                {link.path && (
                                    <Link to={link.path}>{link.text}</Link>
                                )}
                                {link.href && (
                                    <a
                                        href={link.href}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {link.text}
                                    </a>
                                )}
                            </li>
                        ))}
                    </ul>
                    <ul className="footer__links-list">
                        <li>
                            <span className="copyright">
                                {footerContent.copyright}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};
