import { useContext } from 'react';

import {
    DeviceContext,
    DeviceContextInterface,
} from '../context/DeviceContext';

export const useDeviceContext = (): DeviceContextInterface => {
    const context = useContext(DeviceContext);

    return {
        ...context,
    };
};
