import { FC, ReactNode, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { HeaderResponseModel } from '../../services';

import { QueryKeys } from '../../domain/QueryKeys';

import {
    useHeaderService,
    useSaveSubscriptionExpiryFeedback,
} from '../../hooks/data/useHeaderService';
import { useImpersonateUser } from '../../hooks/useImpersonateUser';

import snowplow from '../../snowplow-tracking';
import { SubscriptionExpiryBar } from './SubscriptionExpiryBar';
import { SubscriptionExpiryModal } from './SubscriptionExpiryModal';

type SubscriptionExpiryProps = {
    children?: ReactNode;
};

const componentId = 'subscription-expiry';

/**
 * Toggle shouldShowExpiryBanner property in the headerServiceQuery cache
 * @param params.state The headerService data if it's available
 * @param params.shouldShowExpiryBanner The updated value for shouldShowExpiryBanner
 * @returns new headerData
 */
const updateHeaderQueryData = ({
    state,
    shouldShowExpiryBanner,
}: {
    state?: HeaderResponseModel;
    shouldShowExpiryBanner: boolean;
}): HeaderResponseModel | undefined => {
    if (!state) {
        return state;
    }
    return {
        ...state,
        subscriptionExpiry: {
            ...state.subscriptionExpiry,
            shouldShowExpiryBanner,
        },
    };
};

const trackEvent = (
    campaign_name: 'subscription-expiry-accept' | 'subscription-expiry-decline'
) => {
    snowplow.trackPromotionClick({
        campaign_name,
        interaction_type: 'contact_request',
    });
};

export const SubscriptionExpiry: FC<SubscriptionExpiryProps> = ({
    children,
}) => {
    const queryClient = useQueryClient();
    const { data } = useHeaderService();
    const { mutate } = useSaveSubscriptionExpiryFeedback();
    const { isImpersonating } = useImpersonateUser();

    const subscriptionData = data?.subscriptionExpiry;
    const expiryDate = subscriptionData?.subscriptionExpiryDate ?? '';

    const [modalOpen, setModalOpen] = useState(false);
    const [feedbackRequired, setFeedbackRequired] = useState(false);

    const hideExpiryBanner = () => {
        queryClient.setQueryData(
            [QueryKeys.getHeader],
            updateHeaderQueryData({
                state: data,
                shouldShowExpiryBanner: false,
            })
        );
    };

    const submitSubscriptionExpiryFeedback = (repliedTo: boolean) => {
        if (isImpersonating) {
            return;
        }
        return mutate(repliedTo);
    };

    const onCloseButtonClick = () => {
        hideExpiryBanner();

        // user has not confirmed or declined therefore set feedback to false
        submitSubscriptionExpiryFeedback(false);
    };

    const onCancelClick = () => {
        hideExpiryBanner();

        setModalOpen(true);
        setFeedbackRequired(true);

        submitSubscriptionExpiryFeedback(true);
        trackEvent('subscription-expiry-decline');
    };
    const onConfirmationClick = () => {
        hideExpiryBanner();

        setModalOpen(true);

        submitSubscriptionExpiryFeedback(true);
        trackEvent('subscription-expiry-accept');
    };

    return (
        <>
            {subscriptionData && (
                <SubscriptionExpiryBar
                    componentId={componentId}
                    show={subscriptionData.shouldShowExpiryBanner}
                    handleCancelClick={onCancelClick}
                    handleCloseButtonClick={onCloseButtonClick}
                    handleConfirmationClick={onConfirmationClick}
                    expiryDate={expiryDate}
                />
            )}
            {children}
            {subscriptionData && (
                <SubscriptionExpiryModal
                    modalOpen={modalOpen}
                    toggleModal={setModalOpen}
                    componentId={componentId}
                    feedbackRequired={feedbackRequired}
                />
            )}
        </>
    );
};
