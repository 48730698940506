import { FC, createContext, useMemo } from 'react';

import LZUTF8 from 'lzutf8';
import { useLocation, useNavigate } from 'react-router-dom';

import { ContentFilter } from '../services';

import { Pages } from '../domain/Pages';

export type FilterObj = Record<number, ContentFilter[]>;

export interface ArticleSearchQueries {
    page: number;
    term: string;
    tab: number;
    filterObj: FilterObj;
}

type GetSearchParamsReturn = { searchParams: string; redirectPath: string };
export interface SetQueriesOptions {
    fromSearch?: boolean;
    fromFilter?: boolean;
    fromPaging?: boolean;
    redirectTo?: Pages;
}

type UpdatedQueries = ArticleSearchQueries & {
    selectedFilters: ContentFilter[];
};

export interface FilterContextInterface {
    queries: ArticleSearchQueries;
    setQueries(values: Partial<UpdatedQueries>, opts?: SetQueriesOptions): void;
    getSearchParams(
        values: Partial<UpdatedQueries>,
        opts?: SetQueriesOptions
    ): GetSearchParamsReturn;
}

export const initState: ArticleSearchQueries = {
    page: 0,
    term: '',
    tab: 0,
    filterObj: {},
};

export const initQueries = { ...initState };

const initContextState: FilterContextInterface = {
    queries: initQueries,
    setQueries: () => null,
    getSearchParams: () => ({ searchParams: '', redirectPath: '' }),
};

const encodedString = (queriesObj: Partial<ArticleSearchQueries>): string => {
    try {
        if (Object.keys(queriesObj).length <= 0) {
            return '';
        }
        const jsonStr = JSON.stringify(queriesObj);
        return encodeURIComponent(
            LZUTF8.compress(jsonStr, { outputEncoding: 'Base64' })
        );
    } catch (error) {
        return '';
    }
};

const fromQueryString = (queryString: string): ArticleSearchQueries => {
    try {
        const params = new URLSearchParams(queryString);
        const compressed = decodeURIComponent(params.get('queries') || '');
        const jsonStr = LZUTF8.decompress(compressed, {
            inputEncoding: 'Base64',
            outputEncoding: 'String',
        }) as string;

        if (!jsonStr) {
            return initState;
        }
        const queries = JSON.parse(jsonStr) as ArticleSearchQueries;
        const pageNumber = +queries.page;

        return {
            tab: queries.tab || 0,
            page: isNaN(pageNumber) ? 0 : pageNumber,
            term: queries.term || '',
            filterObj: queries.filterObj || {},
        };
    } catch (error) {
        console.log(error);
        return initState;
    }
};

const compressFilterObj = (obj: ArticleSearchQueries): FilterObj | null => {
    const filterObj: FilterObj = {
        ...obj.filterObj,
    };
    const filtered = obj.filterObj[obj.tab]?.filter(
        (f) => f.entries && f.entries.length > 0
    );
    if (filtered?.length > 0) {
        filterObj[obj.tab] = filtered;
    }
    Object.keys(filterObj).forEach((tabIndex) => {
        const tabIndx = +tabIndex;
        if (filterObj) {
            const f = filterObj[tabIndx];
            if (!f?.length) {
                delete filterObj[tabIndx];
            }
        }
    });

    if (Object.keys(filterObj).length > 0) {
        return filterObj;
    }

    return null;
};

const sanitizeQueries = (
    obj: ArticleSearchQueries
): Partial<ArticleSearchQueries> | null => {
    const results: Partial<ArticleSearchQueries> = {};
    if (obj.page) {
        results.page = obj.page;
    }
    if (obj.term) {
        results.term = obj.term;
    }
    if (obj.tab !== undefined) {
        results.tab = obj.tab;
    }
    const filterObj = compressFilterObj(obj);
    if (filterObj) {
        results.filterObj = filterObj;
    }
    if (Object.keys(results).length <= 0) {
        return {};
    }
    return results;
};

const getRedirectPath = (pathname: string, opts?: SetQueriesOptions) => {
    if (pathname !== Pages.search && opts?.fromSearch) {
        return Pages.search;
    }
    if (opts?.redirectTo) {
        return opts.redirectTo;
    }
    return '';
};

export const FilterContext =
    createContext<FilterContextInterface>(initContextState);

type FilterContextProviderProps = {
    children?: React.ReactNode;
};

const FilterContextProvider: FC<FilterContextProviderProps> = ({
    children,
}) => {
    const navigate = useNavigate();
    const { search, pathname } = useLocation();

    const getSearchParams = (
        values: Partial<UpdatedQueries>,
        opts?: SetQueriesOptions
    ): GetSearchParamsReturn => {
        const queries = fromQueryString(search);
        const { selectedFilters, filterObj, ...rest } = values;

        const currentTabFilters = filterObj?.[queries.tab] ?? [];
        const currentTabFiltersUpdated = selectedFilters
            ? selectedFilters
            : currentTabFilters;

        const newFilterObj: FilterObj = {
            ...(filterObj || {}),
            [queries.tab]: currentTabFiltersUpdated,
        };
        const newQueries = { ...queries, ...rest, filterObj: newFilterObj };

        if (opts?.fromSearch) {
            newQueries.page = 0;
            newQueries.filterObj = {};
        }

        if (opts?.fromFilter) {
            newQueries.page = 0;
        }

        const queriesSanitized = sanitizeQueries(newQueries);
        const searchParams = queriesSanitized
            ? encodedString(queriesSanitized)
            : '';

        const redirectPath = getRedirectPath(pathname, opts);

        return {
            searchParams,
            redirectPath,
        };
    };

    const setQueries = (
        values: Partial<UpdatedQueries>,
        opts?: SetQueriesOptions
    ) => {
        const { searchParams, redirectPath } = getSearchParams(values, opts);

        if (redirectPath) {
            navigate({
                pathname: redirectPath,
                search: `?queries=${searchParams}`,
            });
        } else {
            const urlSearchParams = new URLSearchParams(window.location.search);
            urlSearchParams.set('queries', searchParams);
            navigate(
                {
                    search: urlSearchParams.toString(),
                },
                {
                    replace: true,
                }
            );
        }

        window.scrollTo({
            top: 0,
            left: 0,
        });
    };

    const queries = useMemo(() => {
        const queries = fromQueryString(search);

        return {
            ...queries,
        };
    }, [search]);

    const contextValue = useMemo(
        () => ({
            queries,
            setQueries,
            getSearchParams,
        }),
        [queries, setQueries, getSearchParams]
    );

    return (
        <FilterContext.Provider value={contextValue}>
            {children}
        </FilterContext.Provider>
    );
};

export default FilterContextProvider;
