import { FC, ReactNode } from 'react';

import { useLocation } from 'react-router-dom';

import { TabListItem } from '../../hooks/useAnchorTabs';

import bannerArticle from '../../resources/images/page-banners/page-banner-article.jpg';
import bannerDefault from '../../resources/images/page-banners/page-banner-default.jpg';
import bannerDownloads from '../../resources/images/page-banners/page-banner-downloads.jpg';
import bannerMyCru from '../../resources/images/page-banners/page-banner-mycru.jpg';
import bannerNotifications from '../../resources/images/page-banners/page-banner-notifications.jpg';
import bannerPrice from '../../resources/images/page-banners/page-banner-price.jpg';

import {
    ContentControlsGroup,
    ControlGroupProps,
} from '../ContentControls/ContentControls';
import { BannerTheme, PageBanner } from '../PageBanner/PageBanner';
import { PageHeaderControls } from '../PageHeaderControls/PageHeaderControls';
import { AnchorTab, TabList } from '../Tabs/Tabs';

const bannerMap: Record<string, string> = {
    '/account-settings': bannerMyCru,
    '/analysis/article': bannerArticle,
    '/downloads': bannerDownloads,
    '/help': bannerMyCru,
    '/methodologies': bannerMyCru,
    '/mycru': bannerMyCru,
    '/notifications': bannerNotifications,
    '/price': bannerPrice,
    '/termsandconditions': bannerMyCru,
};

const getBannerSrc = (currentPath: string): string => {
    for (const bannerPath of Object.keys(bannerMap)) {
        const match: boolean = currentPath.startsWith(bannerPath);
        if (match) {
            return bannerMap[bannerPath];
        }
    }
    return bannerDefault;
};

const getTabControls = (tabItems: Array<TabListItem>) => {
    if (tabItems.length > 0) {
        return (
            <TabList>
                {tabItems.map(({ label, tab, icon }, tabIndex) => (
                    <AnchorTab
                        key={tabIndex}
                        tab={tab}
                        testId={`tab-${tab}`}
                        icon={icon}
                    >
                        {label}
                    </AnchorTab>
                ))}
            </TabList>
        );
    }
    return undefined;
};

const getEndControls = (contentControls: ControlGroupProps['controls']) => {
    if (contentControls.length > 0) {
        return <ContentControlsGroup controls={contentControls} />;
    }
    return undefined;
};

export type PageHeaderProps = {
    title: string;
    theme?: BannerTheme;
    showBackgroundImage?: boolean;
    tabItems?: Array<TabListItem>;
    contentControls?: ControlGroupProps['controls'];
    className?: string;
    bannerDesktopOnly?: boolean;
    preHeadlineContent?: React.ReactNode;
    postHeadlineContent?: ReactNode;
    children?: ReactNode;
};

export const PageHeader: FC<PageHeaderProps> = ({
    tabItems = [],
    contentControls = [],
    title,
    theme = 'dark',
    showBackgroundImage = true,
    className,
    bannerDesktopOnly,
    children,
    preHeadlineContent,
    postHeadlineContent,
}) => {
    const { pathname } = useLocation();
    const bannerSrc = getBannerSrc(pathname);
    return (
        <PageBanner
            background={showBackgroundImage ? bannerSrc : undefined}
            theme={theme}
            title={title}
            className={className}
            desktopOnly={bannerDesktopOnly}
            preHeadlineContent={preHeadlineContent}
            postHeadlineContent={postHeadlineContent}
        >
            <PageHeaderControls
                tabControls={getTabControls(tabItems)}
                endControls={getEndControls(contentControls)}
            />
            {children}
        </PageBanner>
    );
};
