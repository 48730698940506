import { FC, useEffect } from 'react';

import snowplow from '../../snowplow-tracking';
import { TrackPageViewEvent } from '../../snowplow-tracking/types';

export type PageViewTrackerProps = {
    pageViewData: TrackPageViewEvent;
};

export const PageViewTracker: FC<PageViewTrackerProps> = ({ pageViewData }) => {
    useEffect(() => {
        if (pageViewData) {
            const customUrl = pageViewData.customUrl ?? window.location.href;
            snowplow.pageView({ ...pageViewData, customUrl });
        }
    }, []);

    return null;
};
