import { FC, ReactNode } from 'react';

import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import parse from 'html-react-parser';

import CloseIcon from '../Icons/CloseIcon';
import './InfoBar.scss';

export type InfoBarProps = {
    title: string;
    show: boolean;
    customClass?: string;
    testId?: string;
    actions?: ReactNode;
    contentAlignment?: 'center' | 'left';
    onDismissClick?: () => void;
    transitionFrom?: 'top' | 'bottom';
};
export const InfoBar: FC<InfoBarProps> = ({
    title,
    show,
    customClass,
    testId,
    actions,
    contentAlignment = 'center',
    onDismissClick,
    transitionFrom,
}) => {
    const componentClass = classNames(
        'info-bar',
        { 'info-bar--content-left': contentAlignment === 'left' },
        customClass
    );
    let transitionFromTo = {
        enterFrom: 'translate-y-full',
        leaveTo: 'translate-y-full',
    };
    if (transitionFrom === 'top') {
        transitionFromTo = {
            enterFrom: '-translate-y-full',
            leaveTo: '-translate-y-full',
        };
    }

    return (
        <Transition
            className={componentClass}
            data-testid={testId ?? 'info-bar'}
            show={show}
            enter="transition ease-in duration-75 transform"
            enterTo="translate-y-0"
            leaveFrom="translate-y-0"
            leave="transition ease-in duration-75 transform"
            {...transitionFromTo}
        >
            <div className="info-bar__inner">
                <div className="info-bar__content">
                    <h3 data-testid="info-bar-heading">{parse(title)}</h3>
                    {actions && (
                        <div className="info-bar__actions">{actions}</div>
                    )}
                </div>
                {onDismissClick && (
                    <button
                        onClick={onDismissClick}
                        className="info-bar__close-button"
                    >
                        <CloseIcon />
                        <span className="sr-only">
                            Close information message
                        </span>
                    </button>
                )}
            </div>
        </Transition>
    );
};
