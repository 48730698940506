import React, { FC, isValidElement } from 'react';

import classNames from 'classnames';

import { AnchorProps, Button, ButtonProps, hasHref } from '../Button/Button';

export type ControlProps = {
    text: string;
} & (AnchorProps | ButtonProps);

type El = React.JSX.Element;

type ControlPropsOrEl = ControlProps | El;

export type ControlGroupProps = {
    controls: ControlPropsOrEl[];
};

export const ContentControlsGroup: FC<ControlGroupProps> = ({ controls }) => {
    const buttonClasses = (control: ControlProps) => {
        // temporary custom colour values until we refactor scss variables to custom tailwind theme
        return classNames('border border-cru-blue', {
            'rounded-full [&>span>svg]:fill-cru-blue h-8': control.icon,
            'rounded-md border-cru-sky-blue p-1 pl-4 pr-4':
                control.icon === undefined,
        });
    };
    const textClasses = (control: ControlProps) => {
        // Text element is screen reader only for icon buttons
        return control.icon ? 'sr-only' : 'text-sm text-cru-sky-blue';
    };
    const buttonTitle = (control: ControlProps) => {
        // No need for tooltip (title) if button text is shown (icon not provided)
        return control.icon ? control.text : undefined;
    };

    return (
        <>
            {controls.map((controlOrBookmark: ControlPropsOrEl) => {
                if (isValidElement(controlOrBookmark)) {
                    return controlOrBookmark as React.JSX.Element;
                }

                // If its not an element, must be typed as ControlProps for code below to be correct.
                const control = controlOrBookmark as ControlProps; // NOSONAR
                const isLink = hasHref(control);
                const title = buttonTitle(control);
                const className = buttonClasses(control);

                if (isLink) {
                    return (
                        <Button
                            key={control.text}
                            title={title}
                            className={className}
                            href={control.href}
                            target={control.target}
                        >
                            {control.icon}
                            <span className={textClasses(control)}>
                                {control.text}
                            </span>
                        </Button>
                    );
                }

                return (
                    <Button
                        key={control.text}
                        title={title}
                        className={className}
                        onClick={control.onClick}
                    >
                        {control.icon}
                        <span className={textClasses(control)}>
                            {control.text}
                        </span>
                    </Button>
                );
            })}
        </>
    );
};
