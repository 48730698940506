import { FC } from 'react';

type IconProps = {
    width?: string | number;
    height?: string | number;
    fill?: string;
};

const CircleMinusIcon: FC<IconProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 42 42"
            {...props}
        >
            <g transform="translate(9 9)">
                <rect fill="currentColor" width="24" height="24" opacity={0} />
                <rect
                    fill="currentColor"
                    width="2"
                    height="18"
                    rx="1"
                    transform="translate(21 11) rotate(90)"
                />
            </g>
            <g stroke="currentColor" strokeWidth={2} fill="none">
                <circle cx="21" cy="21" r="21" stroke="none" />
                <circle cx="21" cy="21" r="20" fill="none" />
            </g>
        </svg>
    );
};

export default CircleMinusIcon;
