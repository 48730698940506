export default {
    title: 'Impersonate user',
    statusLabel: 'Currently impersonating:',
    stopImpersonatingBtn: 'Stop impersonating',
    impersonationForm: {
        userInputLabel: 'User email',
        submitBtn: 'Start impersonating',
        serverError: 'Something went wrong with your request please try again',
    },
};
