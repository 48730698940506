import { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useRedirectToUrlService } from '../../hooks/data/useRedirectToUrlService';
import { useRequiredParams } from '../../hooks/useRequiredParams';

import AppLayout from '../../components/Layout/AppLayout';
import { PageHeader } from '../../components/PageHeader/PageHeader';

const RedirectToUrl: FC = () => {
    const { urlPart } = useRequiredParams<'urlPart'>(['urlPart']);
    const { data } = useRedirectToUrlService(urlPart);
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            navigate(data, { replace: true });
        }
    }, [data]);

    return (
        <AppLayout showSpin={true} headerContent={<PageHeader title="" />} />
    );
};

export default RedirectToUrl;
