export default {
    confirmBtn: 'Yes',
    cancelBtn: 'No',
    expiryText:
        '<strong>Your CRUOnline subscription is approaching its renewal in {0}.</strong> Do you want to make changes to your subscription on renewal?',
    modal: {
        acceptedText:
            'Thank you. If you are not the account holder, please contact them to discuss your requirements.',
        declinedText:
            'Thank you. Please provide us with feedback on the service.',
        btnText: 'OK',
    },
};
