import { FC } from 'react';

import { Button } from '../../components/Button/Button';
import { AuthFlowLayout } from '../../components/Layout/AuthFlowLayout/AuthFlowLayout';

export type SessionLimitExceededProps = {
    onCancelButtonClick: () => void;
    onConfirmButtonClick: () => void;
};

const content = {
    pageTitle: 'CRU session limit exceed',
    heading: 'Login',
    textBlock: (
        <p>
            Your login is being used on another browser. Please confirm you want
            to login on this browser forcing a logout from other browsers.
        </p>
    ),
};

export const SessionLimitExceeded: FC<SessionLimitExceededProps> = ({
    onCancelButtonClick,
    onConfirmButtonClick,
}) => (
    <AuthFlowLayout
        pageViewData={{
            title: content.pageTitle,
        }}
        heading={content.heading}
        textBlock={content.textBlock}
        actionBarContent={
            <>
                <Button
                    onClick={onConfirmButtonClick}
                    testId="login-confirm-button"
                >
                    Confirm login
                </Button>

                <Button
                    testId="cancel-login-button"
                    variant="underline"
                    customClass="session-limit-exceeded__cancel-btn"
                    onClick={onCancelButtonClick}
                >
                    Cancel login
                </Button>
            </>
        }
    />
);
