import { FC } from 'react';

import parse from 'html-react-parser';
import type { TooltipRenderProps } from 'react-joyride';

import CloseIcon from '../Icons/CloseIcon';
import './ProductToursTooltip.scss';

export const ProductToursTooltip: FC<TooltipRenderProps> = ({
    continuous,
    step,
    index,
    size,
    tooltipProps,
    primaryProps,
    isLastStep,
    skipProps,
    backProps,
}) => {
    const isSingleStep = isLastStep && index === 0;

    return (
        <div {...tooltipProps} className="product-tours-tooltip">
            <button {...skipProps} className="product-tours-tooltip__close-btn">
                <CloseIcon />
                <span className="sr-only">Close the product tour</span>
            </button>
            {step.title && (
                <h3 className="product-tours-tooltip__title">
                    {parse(step.title as string)}
                </h3>
            )}
            {step.content && (
                <div className="product-tours-tooltip__content">
                    {parse(step.content as string)}
                </div>
            )}

            <div className="product-tours-tooltip__actions">
                {!isSingleStep && (
                    <div className="product-tours-tooltip__actions-first">
                        {!isLastStep && (
                            <button
                                {...skipProps}
                                className="product-tours-tooltip__skip-btn"
                            >
                                <span className="product-tours-tooltip__skip-btn-icon-wrapper">
                                    <CloseIcon width={14} height={14} />
                                </span>
                                <span>Skip</span>
                            </button>
                        )}
                        <span className="product-tours-tooltip__steps">
                            {isLastStep ? 'Tour' : 'tour'} - {index + 1} of{' '}
                            {size}
                        </span>
                    </div>
                )}
                <div className="product-tours-tooltip__actions-last">
                    {index > 0 && (
                        <button
                            {...backProps}
                            className="product-tours-tooltip__nav-btn product-tours-tooltip__back-btn"
                        >
                            Back
                        </button>
                    )}

                    <button
                        {...primaryProps}
                        className="product-tours-tooltip__nav-btn product-tours-tooltip__next-btn"
                    >
                        {continuous && !isLastStep ? 'Next' : 'Close'}
                    </button>
                </div>
            </div>
        </div>
    );
};
