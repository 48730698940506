import { FC } from 'react';

import { useGenericPage } from '../../hooks/data/useGenericPage';
import { useRequiredParams } from '../../hooks/useRequiredParams';

import AppLayout from '../../components/Layout/AppLayout';
import { PageHeader } from '../../components/PageHeader/PageHeader';

import './GenericPage.scss';

const GenericPage: FC = () => {
    const { pageName } = useRequiredParams<'pageName'>(['pageName']);

    const { data, isLoading } = useGenericPage(pageName);

    const midContent = (
        <div className="chw-generic-page__content">
            <>
                {data?.bodytext && (
                    <div
                        className="richtext"
                        data-testid="generic-page-bodytext"
                        dangerouslySetInnerHTML={{ __html: data?.bodytext }}
                    />
                )}
            </>
        </div>
    );

    const headerContent = (
        <PageHeader title={data?.title || ''} bannerDesktopOnly />
    );

    const pageTitle = data?.title ? `CRU ${data.title}` : '';

    return (
        <AppLayout
            showSpin={isLoading}
            headerContent={headerContent}
            middleContent={midContent}
            pageName={data?.title || ''}
            pageViewData={pageTitle ? { title: pageTitle } : undefined}
        />
    );
};

export default GenericPage;
