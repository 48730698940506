import React, { FC, Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

import CloseIcon from '../Icons/CloseIcon';
import './Modal.scss';

export type ModalProps = {
    title?: React.ReactNode;
    content: React.ReactNode;
    open: boolean;
    onClose(): void;
    variant?: 'standard' | 'large';
    actions?: React.ReactNode;
    className?: string;
};
export const Modal: FC<ModalProps> = ({
    title,
    content,
    open,
    onClose,
    variant = 'standard',
    actions,
    className,
}) => {
    const classes = classNames(
        'modal',
        {
            'modal--large': variant === 'large',
        },
        className
    );
    return (
        <Transition show={open} as={Fragment}>
            <Dialog className={classes} open={open} onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="animation-enter"
                    leave="animation-leave"
                    enterFrom="animation-overlay-enter-from"
                    enterTo="animation-overlay-enter-to"
                    leaveFrom="animation-overlay-leave-from"
                    leaveTo="animation-overlay-leave-to"
                >
                    <Dialog.Overlay className="modal__overlay" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="animation-enter"
                    enterFrom="animation-enter-from"
                    enterTo="animation-enter-to"
                    leave="animation-leave"
                    leaveFrom="animation-leave-from"
                    leaveTo=""
                >
                    <Dialog.Panel className="modal__dialog">
                        <button
                            onClick={onClose}
                            className="modal__close-button"
                        >
                            <CloseIcon />
                            <span className="sr-only">Close dialog</span>
                        </button>
                        {title && (
                            <Dialog.Title as="h3" className="modal__title">
                                {title}
                            </Dialog.Title>
                        )}
                        <div className="modal__content">{content}</div>
                        {actions && (
                            <div className="modal__actions">{actions}</div>
                        )}
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
};
