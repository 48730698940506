import { useContext } from 'react';

import {
    ProductToursContext,
    ProductToursContextInterface,
} from '../context/ProductToursContextProvider';

export const useProductToursContext = (): ProductToursContextInterface => {
    const context = useContext(ProductToursContext);

    return {
        ...context,
    };
};
