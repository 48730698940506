import React, {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    ReactNode,
    useMemo,
} from 'react';

import classNames from 'classnames';

import { isExternal } from '../../util/isExternalUrl';

import { Link } from '../Link/Link';
import './Button.scss';

interface ButtonBaseProps {
    customClass?: string;
    size?: 'default' | 'small';
    variant?: 'standard' | 'outline' | 'underline';
    theme?: 'light' | 'dark';
    iconPosition?: 'left' | 'right';
    icon?: ReactNode;
    iconOnly?: boolean;
    active?: boolean;
    testId?: string;
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
    ButtonBaseProps;

export type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
    href: string;
} & ButtonBaseProps;

export type ButtonConditionalProps = {
    (props: ButtonProps): React.JSX.Element;
    (props: AnchorProps): React.JSX.Element;
};

// Check if href exists in props
export const hasHref = (
    props: ButtonProps | AnchorProps
): props is AnchorProps => 'href' in props;

export const Button: ButtonConditionalProps = ({
    variant,
    size,
    icon,
    iconPosition = 'left',
    iconOnly,
    customClass,
    children,
    testId,
    theme,
    ...otherProps
}: ButtonProps | AnchorProps) => {
    const btnClass = classNames(
        'button',
        {
            'dark-theme': !theme || theme === 'dark',
            'light-theme': theme === 'light',
            'button--standard': !variant || variant === 'standard',
            'button--outline': variant === 'outline',
            'button--underline': variant === 'underline',
            'button--small': size === 'small',
            'button--with-icon': iconOnly,
            'button--with-icon-left': iconPosition === 'left',
            'button--with-icon-right': iconPosition === 'right',
        },
        customClass
    );

    const buttonContent = useMemo(
        () => (
            <span className="button__inner">
                {iconOnly ? (
                    <span>{icon}</span>
                ) : (
                    <>
                        {iconPosition === 'left' && <span>{icon}</span>}
                        {children}
                        {iconPosition === 'right' && <span>{icon}</span>}
                    </>
                )}
            </span>
        ),
        [children, iconPosition, icon]
    );

    // anchor render
    if (hasHref(otherProps)) {
        if (isExternal(otherProps.href)) {
            return (
                <a data-testid={testId} className={btnClass} {...otherProps}>
                    {buttonContent}
                </a>
            );
        }
        return (
            <Link
                data-testid={testId}
                className={btnClass}
                to={otherProps.href}
                {...otherProps}
            >
                {buttonContent}
            </Link>
        );
    }

    // button render
    return (
        <button
            data-testid={testId}
            className={btnClass}
            {...(otherProps as ButtonProps)}
        >
            {buttonContent}
        </button>
    );
};
