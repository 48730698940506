import { FC } from 'react';

import { useImpersonateUser } from '../../hooks/useImpersonateUser';

import { Button } from '../Button/Button';
import { InfoBar } from '../InfoBar/InfoBar';
import content from './content';

export const ImpersonationBar: FC = () => {
    const { isImpersonating, userName, stopImpersonation } =
        useImpersonateUser();

    return (
        <InfoBar
            customClass="sticky bottom-0 z-50 border-t-2 border-yellow-400"
            show={isImpersonating}
            title={`${content.title} ${userName}`}
            actions={
                <Button variant="outline" onClick={stopImpersonation}>
                    {content.btn}
                </Button>
            }
        />
    );
};
