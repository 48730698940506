import { FC } from 'react';

import { ToastContainer } from 'react-toastify';

export type MessageAlertProps = {
    autoCloseDuration?: number;
};

export const MessageAlert: FC<MessageAlertProps> = ({
    autoCloseDuration = 2000,
}) => (
    <ToastContainer
        position="top-right"
        autoClose={autoCloseDuration}
        hideProgressBar
        theme="light"
    />
);

export * from 'react-toastify';
