import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { getConfigValue } from './config';

const appInsightsKey = getConfigValue('APPLICATIONINSIGHTS_CONNECTION_STRING');
let appInsights: ApplicationInsights | null = null;

export const logError = (error: Error) => {
    appInsights?.trackException({
        exception: error,
    });

    const traceId = appInsights?.context.telemetryTrace.traceID;
    if (traceId) {
        console.error(`Operation ID: ${traceId}`);
    }
};

if (appInsightsKey) {
    const reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
        config: {
            connectionString: appInsightsKey,
            extensions: [reactPlugin],
            appId: 'cru-fe-web',
            enableAutoRouteTracking: true,
            enableCorsCorrelation: true,
            enableAjaxPerfTracking: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            autoTrackPageVisitTime: true,
            correlationHeaderExcludedDomains: [
                'auth.dev.crugroup.com',
                'auth.uat5.crugroup.com',
                'auth.uat4.crugroup.com',
                'auth.crugroup.com',
            ],
        },
    });
}

export default appInsights;
