import { SelfDescribingJson } from '@snowplow/tracker-core';

import {
    ArticleEntity,
    ArticleFiltersEntity,
    ArticleToolEvent,
    BookmarkEvent,
    DocumentDownloadEvent,
    DocumentEntity,
    FilterArticleListingEvent,
    GenericControlEvent,
    InlineDocumentEvent,
    LinkClickArticleListingEntity,
    PaginationEntity,
    PriceEntity,
    PriceTableDownloadEvent,
    PriceTableEntity,
    PromotionClickEvent,
    SaveSelectionEvent,
    SearchEntity,
    ServiceEntity,
    SiteNavigationEntity,
    UserEntity,
} from './types';

type Version = `${number}-${number}-${number}`;

type EntityNames =
    | 'user'
    | 'document'
    | 'service'
    | 'article'
    | 'link_click_article_listing'
    | 'search'
    | 'prices_table'
    | 'article_filters'
    | 'pagination'
    | 'price'
    | 'site_navigation';

type EventNames =
    | 'article_tool'
    | 'document_download'
    | 'site_search'
    | 'inline_document_paging'
    | 'price_table_download'
    | 'filter_article_listing'
    | 'promotion_click'
    | 'bookmark'
    | 'save_selection'
    | 'generic_control';

const entityBasePath = 'iglu:com.crugroup.entities';
const eventBasePath = 'iglu:com.crugroup.events';

export const getEntitySchema = <T>(
    name: EntityNames,
    data: T,
    version: Version = '1-0-0'
): SelfDescribingJson<T> => ({
    schema: `${entityBasePath}/${name}/jsonschema/${version}`,
    data,
});

export const getEventSchema = <T>(
    name: EventNames,
    data: T,
    version: Version = '1-0-0'
): SelfDescribingJson<T> => ({
    schema: `${eventBasePath}/${name}/jsonschema/${version}`,
    data,
});

export const getUserEntitySchema = (
    data: UserEntity,
    version: Version = '1-0-0'
): SelfDescribingJson<UserEntity> => {
    return getEntitySchema<UserEntity>('user', data, version);
};

export const getDocumentEntitySchema = (
    data: DocumentEntity,
    version: Version = '1-0-0'
): SelfDescribingJson<DocumentEntity> => {
    return getEntitySchema<DocumentEntity>('document', data, version);
};

export const getServiceEntitySchema = (
    data: ServiceEntity,
    version: Version = '1-0-0'
): SelfDescribingJson<ServiceEntity> => {
    return getEntitySchema<ServiceEntity>('service', data, version);
};

export const getArticleEntitySchema = (
    data: ArticleEntity,
    version: Version = '1-0-0'
): SelfDescribingJson<ArticleEntity> => {
    return getEntitySchema<ArticleEntity>('article', data, version);
};

export const getLinkClickArticleListingEntitySchema = (
    data: LinkClickArticleListingEntity,
    version: Version = '1-0-0'
): SelfDescribingJson<LinkClickArticleListingEntity> => {
    return getEntitySchema<LinkClickArticleListingEntity>(
        'link_click_article_listing',
        data,
        version
    );
};

export const getPriceTableEntitySchema = (
    data: PriceTableEntity,
    version: Version = '1-0-0'
): SelfDescribingJson<PriceTableEntity> => {
    return getEntitySchema<PriceTableEntity>('prices_table', data, version);
};

export const getArticleFiltersEntitySchema = (
    data: ArticleFiltersEntity,
    version: Version = '1-0-0'
): SelfDescribingJson<ArticleFiltersEntity> => {
    return getEntitySchema<ArticleFiltersEntity>(
        'article_filters',
        data,
        version
    );
};

export const getSearchEntitySchema = (
    data: SearchEntity,
    version: Version = '1-0-0'
): SelfDescribingJson<SearchEntity> => {
    return getEntitySchema<SearchEntity>('search', data, version);
};

export const getPaginationEntitySchema = (
    data: PaginationEntity,
    version: Version = '1-0-0'
): SelfDescribingJson<PaginationEntity> => {
    return getEntitySchema<PaginationEntity>('pagination', data, version);
};

export const getSiteNavigationSchema = (
    data: SiteNavigationEntity,
    version: Version = '1-0-0'
): SelfDescribingJson<SiteNavigationEntity> => {
    return getEntitySchema<SiteNavigationEntity>(
        'site_navigation',
        data,
        version
    );
};

export const getPriceEntitySchema = (
    data: PriceEntity,
    version: Version = '1-0-0'
): SelfDescribingJson<PriceEntity> => {
    return getEntitySchema<PriceEntity>('price', data, version);
};

export const getArticleToolEventSchema = (
    data: ArticleToolEvent,
    version: Version = '1-0-0'
): SelfDescribingJson<ArticleToolEvent> => {
    return getEventSchema<ArticleToolEvent>('article_tool', data, version);
};

export const getDocumentDownloadEventSchema = (
    data: DocumentDownloadEvent,
    version: Version = '1-0-0'
): SelfDescribingJson<DocumentDownloadEvent> => {
    return getEventSchema<DocumentDownloadEvent>(
        'document_download',
        data,
        version
    );
};

export const getPriceTableDownloadEventSchema = (
    data: PriceTableDownloadEvent,
    version: Version = '1-0-0'
): SelfDescribingJson<PriceTableDownloadEvent> => {
    return getEventSchema<PriceTableDownloadEvent>(
        'price_table_download',
        data,
        version
    );
};

export const getInlineDocumentPagingEventSchema = (
    data: InlineDocumentEvent,
    version: Version = '1-0-0'
): SelfDescribingJson<InlineDocumentEvent> => {
    return getEventSchema<InlineDocumentEvent>(
        'inline_document_paging',
        data,
        version
    );
};

export const getFilterArticleListingEventSchema = (
    data: FilterArticleListingEvent,
    version: Version = '1-0-0'
): SelfDescribingJson<FilterArticleListingEvent> => {
    return getEventSchema<FilterArticleListingEvent>(
        'filter_article_listing',
        data,
        version
    );
};

export const getPromotionClickEventSchema = (
    data: PromotionClickEvent,
    version: Version = '1-0-0'
): SelfDescribingJson<PromotionClickEvent> => {
    return getEventSchema<PromotionClickEvent>(
        'promotion_click',
        data,
        version
    );
};

export const getBookmarkEventSchema = (
    data: BookmarkEvent,
    version: Version = '1-0-0'
): SelfDescribingJson<BookmarkEvent> => {
    return getEventSchema<BookmarkEvent>('bookmark', data, version);
};

export const getSaveSelectionEventSchema = (
    data: SaveSelectionEvent,
    version: Version = '1-0-1'
): SelfDescribingJson<SaveSelectionEvent> => {
    return getEventSchema<SaveSelectionEvent>('save_selection', data, version);
};

export const getGenericControlEventSchema = (
    data: GenericControlEvent,
    version: Version = '1-0-0'
): SelfDescribingJson<GenericControlEvent> => {
    return getEventSchema<GenericControlEvent>(
        'generic_control',
        data,
        version
    );
};
