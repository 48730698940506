import { FC, useEffect, useRef } from 'react';

import parse from 'html-react-parser';
import { dasherize, underscore } from 'inflection';
import { FallbackProps } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { ApiError } from '../../services';

import { ApiErrorShortened, isServerError } from '../../util/error-handling';

import { usePyramidService } from '../../hooks/data/usePyramidService';
import { useAuth } from '../../hooks/useAuth';

import { Button } from '../../components/Button/Button';
import AppLayout from '../../components/Layout/AppLayout';
import { PageHeader } from '../../components/PageHeader/PageHeader';

import './ErrorPage.scss';

export const testId = 'error-boundary';

interface Content {
    title: string;
    pageTitle: string;
    message: string;
    additional?: React.ReactNode;
}

const LogoutButton: FC = () => {
    const { destroySession } = useAuth();
    return (
        <Button variant="underline" onClick={() => destroySession()}>
            Logout
        </Button>
    );
};

const genericTitle = 'Something went wrong';

export const content: Record<string | number, Content> = {
    generic: {
        pageTitle: 'CRU - Something went wrong',
        title: genericTitle,
        message: `
            <p>We have encountered a technical problem. Please try going back or refreshing the page.</p>
            `,
    },
    noSubscriptions: {
        pageTitle: 'CRU - No active subscriptions',
        title: 'No active subscriptions',
        message:
            '<p>You have no active subscriptions at present. <br />Please email <a href="mailto:customer.services@crugroup.com">customer.services@crugroup.com</a> to re-activate your subscription.</p>',
        additional: <LogoutButton />,
    },
    400: {
        pageTitle: 'CRU - 400 Bad request',
        title: genericTitle,
        message:
            '<p><strong>400 error</strong></p><p>We have encountered a technical problem. Please try going back or refreshing the page.</p>',
    },
    401: {
        pageTitle: 'CRU - 401 Unauthorized',
        title: genericTitle,
        message:
            '<p><strong>401 error</strong></p><p>We have encountered a technical problem. Please try going back or refreshing the page.</p>',
    },
    403: {
        pageTitle: 'CRU - 403 Access forbidden',
        title: 'Access forbidden',
        message: `
            <p><strong>403 error</strong></p>
            <p>Your subscription does not include access to this content.</p>
            <p>Please contact <a href="mailto:customer.services@crugroup.com">customer.services@crugroup.com</a> to discuss adding new services to your CRU subscription.</p>
            `,
    },
    404: {
        pageTitle: 'CRU - 404 Page not found',
        title: 'Page not found',
        message: `
            <p><strong>404 error</strong></p>
            <p>This page is no longer available. It may have been moved, removed, or the link has changed.</p>
            <p>Use the global search or the <a href="/analysis">analysis</a> stream to find articles, or the main navigation to locate other content.</p>
            <p>Please contact <a href="mailto:customer.services@crugroup.com">customer.services@crugroup.com</a> if you need further assistance.</p>
            `,
    },
    409: {
        pageTitle: 'CRU - 409 Conflict',
        title: genericTitle,
        message: `<p><strong>409 error</strong></p><p>We have encountered a technical problem. Please try going back or refreshing the page.</p>`,
    },
    412: {
        pageTitle: 'CRU - 412 Precondition failed',
        title: genericTitle,
        message: `<p><strong>412 error</strong></p><p>We have encountered a technical problem. Please try going back or refreshing the page.</p>`,
    },
    500: {
        pageTitle: 'CRU - 500 Internal server error',
        title: genericTitle,
        message: `
            <p><strong>500 internal server error</strong></p>
            <p>We have encountered a technical problem connecting to the server. Please try going back or refreshing the page.</p>
            <p>Please contact <a href="mailto:customer.services@crugroup.com">customer.services@crugroup.com</a> if you need further assistance.</p>
            `,
    },
};

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    const err = error as ApiError | Error;
    const location = useLocation();
    const errorLocation = useRef(location.pathname);
    useEffect(() => {
        if (location.pathname !== errorLocation.current) {
            resetErrorBoundary();
        }
    }, [location.pathname]);

    return <ErrorPage error={err} />;
};

export interface ErrorPageProps {
    error: ApiErrorShortened | Error;
}

export const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
    const { pathname, search } = useLocation();
    const { logPageView } = usePyramidService();

    const isApiError = isServerError(error);
    const errKey = isApiError ? error.status : error.name;

    const errorMsgContent = content[errKey] ?? content['generic'];

    const { pageTitle, title, message, additional } = errorMsgContent;

    const urlPrefixUnderscore = underscore(errKey.toString());
    const urlPrefix = dasherize(urlPrefixUnderscore);

    const customPath = `${pathname}/error/${urlPrefix}${search}`;

    useEffect(() => {
        logPageView({
            path: customPath,
            aCodes: [],
            applicableACodes: [],
        });
    }, []);

    const middleContent = (
        <div className="chw-error-boundary" data-testid={testId}>
            <div className="chw-error-description richtext">
                {parse(message)}
                {additional && (
                    <div className="chw-error-description__additional">
                        {additional}
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <AppLayout
            showSpin={false}
            middleContent={middleContent}
            pageName={title}
            headerContent={<PageHeader title={title} />}
            pageViewData={{
                title: pageTitle,
                customUrl: `${window.location.origin}${customPath}`,
            }}
            hideSubTitleOnMobile
        />
    );
};
