import { FC } from 'react';

import { useFeedbackFormContext } from '../../hooks/useFeedbackFormContext';
import { useImpersonateUser } from '../../hooks/useImpersonateUser';

import { FeedbackButton } from '../FeedbackButton/FeedbackButton';
import './Feedback.scss';
import { FeedbackForm } from './FeedbackForm';

export const Feedback: FC = () => {
    const { feedbackFormVisible, toggleFeedbackForm } =
        useFeedbackFormContext();

    const { isImpersonating } = useImpersonateUser();

    const showForm = () => toggleFeedbackForm(true);
    const hideForm = () => toggleFeedbackForm(false);

    return (
        <>
            <FeedbackButton
                formVisible={feedbackFormVisible}
                showForm={showForm}
            />
            <FeedbackForm
                formVisible={feedbackFormVisible}
                hideForm={hideForm}
                disabled={isImpersonating}
            />
        </>
    );
};
