/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HeaderResponseModel } from '../models/HeaderResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HeaderService {

    /**
     * @returns HeaderResponseModel Success
     * @throws ApiError
     */
    public static getHeader(): CancelablePromise<HeaderResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Header',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param repliedTo
     * @returns any Accepted
     * @throws ApiError
     */
    public static saveSubscriptionExpiryFeedback(
        repliedTo?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Header/SaveSubscriptionExpiryFeedback',
            query: {
                'repliedTo': repliedTo,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
            },
        });
    }

}