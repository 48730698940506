/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserFeedbackViewModel } from '../models/UserFeedbackViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeedbackService {

    /**
     * @param requestBody
     * @returns string Success
     * @throws ApiError
     */
    public static send(
        requestBody?: UserFeedbackViewModel,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Feedback',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}