import { useCallback, useContext } from 'react';

import { useLocation } from 'react-router-dom';

import { Pages } from '../domain/Pages';

import {
    HeaderContext,
    HeaderContextInterface,
} from '../context/HeaderContext';

export type UseHeader = HeaderContextInterface & {
    isActiveNavLink: (link?: string) => boolean;
};

export const useHeader = (): UseHeader => {
    const headerContext = useContext(HeaderContext);
    const location = useLocation();

    const isActiveNavLink = useCallback(
        (link?: string) => {
            if (!link) {
                return false;
            }
            if (
                // extra condition to highlight 'Prices' on single 'Price' page.
                location.pathname.startsWith(Pages.priceDetail) &&
                link.startsWith(Pages.priceDetail)
            ) {
                return true;
            }
            return location.pathname.startsWith(link);
        },
        [location.pathname]
    );

    return {
        ...headerContext,
        isActiveNavLink,
    };
};
