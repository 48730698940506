import type { TypographyOptions } from '@mui/material/styles/createTypography';

export const createTypography = (): TypographyOptions => {
    const Inter = 'Inter, sans-serif';

    return {
        fontFamily:
            'EBGaramond, Inter, "Myriad-Roman", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        body1: {
            fontFamily: Inter,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: 1.4,
        },
        body2: {
            fontFamily: Inter,
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 1.4,
        },
        bodyLarge: {
            fontFamily: Inter,
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: 1.4,
        },
        bodyLargeBold: {
            fontFamily: Inter,
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: 1.4,
        },
        bodyM: {
            fontFamily: Inter,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: 1.4,
        },
        bodyMM: {
            fontFamily: Inter,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: 1.4,
        },
        bodyMB: {
            fontFamily: Inter,
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: 1.4,
        },
        bodyS: {
            fontFamily: Inter,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: 1.4,
        },
        bodySM: {
            fontFamily: Inter,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.4,
        },
        bodySB: {
            fontFamily: Inter,
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: 1.4,
        },
        bodyXS: {
            fontFamily: Inter,
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 1.4,
        },
        bodyXSB: {
            fontFamily: Inter,
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: 1.4,
        },
        bodyXXS: {
            fontFamily: Inter,
            fontSize: '10px',
            fontWeight: 400,
            lineHeight: 1.4,
        },
        button: {
            fontWeight: 600,
        },
        caption: {
            fontFamily: Inter,
            fontSize: '10px',
            fontWeight: 400,
            lineHeight: 1.4,
        },
        subtitle1: {
            fontFamily: Inter,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: 1.1,
        },
        subtitle2: {
            fontFamily: Inter,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.1,
        },
        overline: {
            fontSize: '0.75rem',
            fontWeight: 600,
            letterSpacing: '0.5px',
            lineHeight: 2.5,
            textTransform: 'uppercase',
        },
        h1: {
            fontFamily: Inter,
            fontWeight: 700,
            fontSize: '54px',
            lineHeight: 1.3,
        },
        h2: {
            fontFamily: Inter,
            fontWeight: 700,
            fontSize: '42px',
            lineHeight: 1.3,
        },
        h3: {
            fontFamily: Inter,
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: 1.3,
        },
        h4: {
            fontFamily: Inter,
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: 1.3,
        },
        h5: {
            fontFamily: Inter,
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: 1.3,
        },
        h6: {
            fontFamily: Inter,
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: 1.3,
        },
    };
};
