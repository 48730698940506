import React from 'react';

import classNames from 'classnames';

import './Pill.scss';

export interface PillProps {
    className?: string;
    children: React.ReactNode;
}

export const Pill: React.FC<PillProps> = ({ children, className }) => {
    const classes = classNames('chw-pill', className);
    return (
        <span className={classes} data-testid="pill">
            {children}
        </span>
    );
};
