import {
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
} from '@tanstack/react-query';

import { FeaturesService, UserTour } from '../../services';

import { QueryKeys } from '../../domain/QueryKeys';

import { useAuth } from '../useAuth';

type SuccessCallback = (data: UserTour[]) => void;

type UseFeaturesServiceResult = {
    productTours: (
        successCallback: SuccessCallback
    ) => UseQueryResult<UserTour[]>;
    setTourShown: UseMutationResult<unknown, unknown, string>;
};

export const useFeaturesService = ({
    enabled,
}: {
    enabled: boolean;
}): UseFeaturesServiceResult => {
    const { makeRequest } = useAuth();

    const productTours = (successCallback: SuccessCallback) =>
        useQuery(
            [QueryKeys.getTours],
            () => makeRequest<UserTour[]>(() => FeaturesService.getTours()),
            {
                useErrorBoundary: true,
                staleTime: Infinity, // never refetch the product tours
                onSuccess: (data: UserTour[]) => successCallback(data),
                enabled,
            }
        );

    const setTourShown = useMutation((name: string) =>
        makeRequest(() => FeaturesService.setTour(name))
    );

    return {
        productTours,
        setTourShown,
    };
};
