import { FC, ReactNode } from 'react';

import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LiveAnnouncer } from 'react-aria-live';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';

import { OpenAPI } from './services';

import appInsights, { logError } from './util/appInsights';
import { getConfigValue } from './util/config';

import IsAuthenticatedContextProvider from './context/IsAuthenticatedContext';

import { ErrorFallback } from './views/ErrorPage/ErrorPage';

import './styles/index.scss';

import { App } from './App';

OpenAPI.BASE = getConfigValue('REACT_APP_API_URL');
OpenAPI.WITH_CREDENTIALS = true;

appInsights?.loadAppInsights();

const queryClient = new QueryClient();

type Auth0Props = {
    children: ReactNode;
};

const Auth0: FC<Auth0Props> = ({ children }) => {
    const domain = getConfigValue('REACT_APP_AUTH0_DOMAIN');
    const clientId = getConfigValue('REACT_APP_AUTH0_CLIENT_ID');
    const audience = getConfigValue('REACT_APP_AUTH0_AUDIENCE');
    const redirectUri = `${window.location.origin}/callback`;

    const navigate = useNavigate();

    // redirect user to intended route once authentication is successful
    const onRedirectCallback = (appState: AppState | undefined) => {
        navigate({
            pathname: appState?.returnTo || '/',
            search: appState?.queryString || '',
        });
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience,
                scope: 'openid profile email',
            }}
            onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    );
};

const Root = () => (
    <QueryClientProvider client={queryClient}>
        <LiveAnnouncer>
            <div className="chw-app">
                <Router>
                    <Auth0>
                        <HelmetProvider>
                            <ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                onError={logError}
                            >
                                <IsAuthenticatedContextProvider>
                                    <App />
                                </IsAuthenticatedContextProvider>
                            </ErrorBoundary>
                        </HelmetProvider>
                    </Auth0>
                </Router>
            </div>
        </LiveAnnouncer>
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
);

const rootRender = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

rootRender.render(<Root />);
