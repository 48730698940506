/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserDatatableDownloadModel } from '../models/UserDatatableDownloadModel';
import type { UserDocumentDownloadModel } from '../models/UserDocumentDownloadModel';
import type { UserDocumentViewerModel } from '../models/UserDocumentViewerModel';
import type { UserPageViewModel } from '../models/UserPageViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PyramidService {

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static logDatatableDownload(
        requestBody?: UserDatatableDownloadModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Pyramid/LogDatatableDownload',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static logDocumentDownload(
        requestBody?: UserDocumentDownloadModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Pyramid/LogDocumentDownload',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static logPageView(
        requestBody?: UserPageViewModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Pyramid/LogPageView',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static logDocumentView(
        requestBody?: UserDocumentViewerModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Pyramid/LogDocumentView',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}