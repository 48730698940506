import { FC, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import useHotjar from 'react-use-hotjar';

import { ApiError, OpenAPI } from './services';

import { Pages } from './domain/Pages';
import { QueryKeys } from './domain/QueryKeys';

import { getConfigValue } from './util/config';
import { isServerError } from './util/error-handling';

import DeviceContextProvider from './context/DeviceContext';
import FeedbackFormContextProvider from './context/FeedbackFormContext';
import FilterContextProvider from './context/FilterContext';
import HeaderContextProvider from './context/HeaderContext';
import ImpersonationContextProvider from './context/ImpersonationContext';
import LanguageContextProvider from './context/LanguageContext';
import ProductToursContextProvider from './context/ProductToursContextProvider';

import { useUserCookie } from './hooks/data/useUserCookie';
import { useAuth } from './hooks/useAuth';
import { useEventAnalytics } from './hooks/useEventAnalytics';
import { useIsAuthenticated } from './hooks/useIsAuthenticated';

import Routes from './views/Routes';
import { SessionLimitExceeded } from './views/SessionLimitExceeded/SessionLimitExceeded';

import { MessageAlert } from './components/MessageAlert/MessageAlert';
import { SkipLink } from './components/SkipLink/SkipLink';
import { SubscriptionExpiry } from './components/SubscriptionExpiry/SubscriptionExpiry';

import { theme } from './styles/05-mui-theme';
import { ThemeProvider } from '@mui/material/styles';

import snowplow from './snowplow-tracking';

const noSubscriptionsError: Error = {
    name: 'noSubscriptions',
    message: '',
};

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

/**
 * Conditionally wrap the routes with context providers & global react components.
 * Only apply the wrapper components for pages which require authentication
 * @returns FC
 */
const AppComponents: FC = () => {
    const { pathname } = useLocation();
    const authenticatedPages = Object.values(Pages);
    const isAuthenticatedPage = authenticatedPages.some(
        (authPage) => pathname.indexOf(authPage) >= 0
    );

    if (!isAuthenticatedPage) {
        return <Routes />;
    }

    return (
        <FilterContextProvider>
            <ProductToursContextProvider>
                <ImpersonationContextProvider>
                    <FeedbackFormContextProvider>
                        <SubscriptionExpiry>
                            <Routes />
                        </SubscriptionExpiry>
                    </FeedbackFormContextProvider>
                </ImpersonationContextProvider>
            </ProductToursContextProvider>
        </FilterContextProvider>
    );
};

export const App = () => {
    const [unauthorisedUser, setUnauthorisedUser] = useState(false);
    const [forceLogin, setForceLogin] = useState<'forceLogin' | ''>('');

    const { destroySession, user } = useAuth();
    const { initHotjar } = useHotjar();

    const { isAuthenticated, setHasUserCookie } = useIsAuthenticated();
    const { showBoundary } = useErrorBoundary();

    // set user cookie required for CRU Data Tools & checking if session limit exceeded
    const {
        data: userCookieData,
        isSuccess: userCookieIsSuccess,
        error: userCookieError,
    } = useUserCookie({ enabled: isAuthenticated, forceLogin });

    // initialize Google Analytics
    useEventAnalytics();

    useEffect(() => {
        if (user) {
            // initialize snowplow analytics
            snowplow.initialize(user);
        }
    }, [user]);

    useEffect(() => {
        getConfigValue('REACT_APP_FF_ENABLE_HOTJAR') === 'true' &&
            initHotjar(4975422, 6, false);
    }, [initHotjar]);

    const queryClient = useQueryClient();
    queryClient.setDefaultOptions({
        queries: {
            refetchOnWindowFocus: false,
            retry: (failureCount: number, error) => {
                const err = error as unknown as ApiError | Error;
                if (failureCount === MAX_RETRIES) {
                    return false;
                }

                const serverError = isServerError(err);

                // do not retry if err is an API error with certain status code
                if (
                    serverError &&
                    HTTP_STATUS_TO_NOT_RETRY.includes(err?.status ?? 0)
                ) {
                    return false;
                }

                // retry for all other errors
                return true;
            },
        },
    });

    queryClient.getQueryCache().config.onError = (error, query) => {
        const { status } = error as ApiError;
        const isGetUserCookie = query.queryKey.includes(QueryKeys.useGetCookie);

        // Logout user if unauthorised and session limit has exceeded
        if (unauthorisedUser && isGetUserCookie && status === 403) {
            return destroySession();
        }

        // If unauthorised check if session limit has exceeded
        if (status === 401) {
            setUnauthorisedUser(true);
            return queryClient.refetchQueries([QueryKeys.useGetCookie]);
        }
    };

    useEffect(() => {
        if (userCookieData) {
            OpenAPI.HEADERS = {
                ...OpenAPI.HEADERS,
                ...{ 'X-CRU-Sessiontoken': userCookieData },
            };
            setHasUserCookie(true);
        } else if (userCookieIsSuccess && userCookieData === null) {
            // if userCookie has no data (statusCode 204) then we know that user has no active subscriptions
            showBoundary(noSubscriptionsError);
        } else if (userCookieError && userCookieError.status !== 451) {
            showBoundary(userCookieError);
        }
    }, [userCookieData, userCookieIsSuccess, userCookieError]);

    const onConfirmForceLoginButtonClick = () => {
        setForceLogin('forceLogin');
    };

    if (userCookieError?.status === 451) {
        return (
            <SessionLimitExceeded
                onCancelButtonClick={destroySession}
                onConfirmButtonClick={onConfirmForceLoginButtonClick}
            />
        );
    }

    return (
        <>
            <SkipLink />
            <DeviceContextProvider>
                <LanguageContextProvider>
                    <HeaderContextProvider>
                        <ThemeProvider theme={theme}>
                            <AppComponents />
                            <MessageAlert />
                        </ThemeProvider>
                    </HeaderContextProvider>
                </LanguageContextProvider>
            </DeviceContextProvider>
        </>
    );
};
