export enum FeedbackInputIds {
    excellent = 'excellent',
    good = 'good',
    average = 'average',
    fair = 'fair',
    poor = 'poor',
    page = 'page',
    platform = 'platform',
}

export enum FeedbackTargetIds {}

export type FeedbackInputItem = {
    id: FeedbackInputIds;
    title: string;
    value: boolean;
};

export const FEEDBACK_RATING_LIST: FeedbackInputItem[] = [
    {
        id: FeedbackInputIds.excellent,
        title: 'Excellent',
        value: false,
    },
    {
        id: FeedbackInputIds.good,
        title: 'Good',
        value: false,
    },
    {
        id: FeedbackInputIds.average,
        title: 'Average',
        value: false,
    },
    {
        id: FeedbackInputIds.fair,
        title: 'Fair',
        value: false,
    },
    {
        id: FeedbackInputIds.poor,
        title: 'Poor',
        value: false,
    },
];

export const FEEDBACK_TARGET_LIST: FeedbackInputItem[] = [
    {
        id: FeedbackInputIds.page,
        title: 'Feedback was about this page',
        value: false,
    },
    {
        id: FeedbackInputIds.platform,
        title: 'Feedback was about the platform',
        value: false,
    },
];
