import { FC, ReactNode, createContext, useMemo, useState } from 'react';

import { UserTour } from '../services';

import { useFeaturesService } from '../hooks/data/useFeaturesService';
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';

import { campaignTourNames } from '../components/ProductTours/ProductTours';

export type ProductTour = {
    enabled: boolean;
} & UserTour & { campaignId?: number };

type ProductToursContextProvider = {
    children?: ReactNode;
};

type UpdateTours = {
    tours: ProductTour[];
    enabled: boolean;
    name: string;
};

export type ProductToursContextInterface = {
    startTour: (name: string) => void;
    stopTour: (name: string) => void;
    tours: ProductTour[];
    shouldRun: boolean;
};

export const ProductToursContext = createContext<ProductToursContextInterface>({
    startTour: () => undefined,
    stopTour: () => undefined,
    tours: [],
    shouldRun: false,
});

export const updateTours = ({ tours, enabled, name }: UpdateTours) =>
    tours.map((tour) => {
        if (tour.tourName === name) {
            return {
                ...tour,
                enabled,
            };
        }
        return tour;
    });

export const setInitialTourState = (toursData: UserTour[]): ProductTour[] => {
    const tours = toursData.map((tour) => {
        return {
            ...tour,
            tourSteps: (tour.tourSteps || []).map((step) => {
                return {
                    ...step,
                    disableBeacon: true,
                };
            }),
        };
    });
    const welcomeTour = tours.find(({ tourName }) => tourName === 'welcome');
    const enableWelcomeTour = welcomeTour && welcomeTour.tourShown === false;

    if (enableWelcomeTour) {
        return tours.map((tour) => {
            if (tour.tourName === 'welcome') {
                return {
                    ...tour,
                    enabled: true,
                };
            }
            return {
                ...tour,
                enabled: false,
            };
        });
    }

    return tours.map((tour) => {
        const enabled = !tour.tourShown;
        return {
            ...tour,
            enabled,
        };
    });
};

type ProductTourState = {
    shouldRun: boolean;
    tours: ProductTour[];
};

const ProductToursContextProvider: FC<ProductToursContextProvider> = ({
    children,
}) => {
    const { hasUserCookie } = useIsAuthenticated();
    const [state, setState] = useState<ProductTourState>({
        shouldRun: false,
        tours: [],
    });

    const { productTours: getProductTours, setTourShown } = useFeaturesService({
        enabled: hasUserCookie,
    });

    // set local state after API call to get productTours has completed
    const onProductToursFetchedSuccess = (initialToursData: UserTour[]) => {
        const tours = setInitialTourState([...initialToursData]);
        // set Campaign Tours as enabled, as these are triggered purely by presence of Campaign data
        for (const t of tours) {
            if (t.tourName && campaignTourNames.includes(t.tourName)) {
                t.enabled = true;
            }
        }
        setState({ ...state, tours });
    };

    getProductTours(onProductToursFetchedSuccess);

    const startTour = (name: string) => {
        const updatedTours = updateTours({
            tours: state.tours,
            enabled: true,
            name,
        });
        setState({ ...state, tours: updatedTours, shouldRun: true });
    };

    const stopTour = (name: string) => {
        const updatedTours = updateTours({
            tours: state.tours,
            enabled: false,
            name,
        });
        setState({ ...state, tours: updatedTours, shouldRun: false });

        setTourShown.mutate(name);
    };

    const contextValue = useMemo(
        () => ({
            startTour,
            stopTour,
            tours: state.tours,
            shouldRun: state.shouldRun,
        }),
        [state.shouldRun, state.tours]
    );

    return (
        <ProductToursContext.Provider value={contextValue}>
            {children}
        </ProductToursContext.Provider>
    );
};

export default ProductToursContextProvider;
