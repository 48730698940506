import {
    createTheme as createMuiTheme,
    responsiveFontSizes,
} from '@mui/material/styles';
import type { Theme } from '@mui/material/styles/createTheme';

import { createOptions as createBaseOptions } from './base/create-options';

declare module '@mui/material/styles/createPalette' {
    interface ColorRange {
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        900: string;
    }

    interface PaletteColor {
        lightest?: string;
        darkest?: string;
        alpha4?: string;
        alpha8?: string;
        alpha12?: string;
        alpha30?: string;
        alpha40?: string;
        alpha50?: string;
    }

    interface PaletteCore {
        azure: string;
        deepAubergine: string;
        blueBlack: string;
        white: string;
    }

    interface PaletteLinearGradients {
        bgDarkBlueGradient: string;
        bgLightGreyGradient: string;
    }

    interface Palette {
        monochromes: {
            greys: ColorRange;
            transparent: Omit<PaletteOptions['primary'], 'main'>;
        };
        variations: {
            aubergine: PaletteOptions['primary'];
            blueBlack: PaletteOptions['primary'];
            azure: PaletteOptions['primary'];
            ceasiumData: PaletteOptions['primary'];
            lithiumInsight: PaletteOptions['primary'];
            strontiumStrategy: PaletteOptions['primary'];
            copperCommunities: PaletteOptions['primary'];
        };
        core: PaletteCore;
        linearGradients: PaletteLinearGradients;
    }

    interface PaletteOptions {
        monochromes?: {
            greys: ColorRange;
            transparent: Omit<PaletteOptions['primary'], 'main'>;
        };
        variations?: {
            aubergine: PaletteOptions['primary'];
            blueBlack: PaletteOptions['primary'];
            azure: PaletteOptions['primary'];
            ceasiumData: PaletteOptions['primary'];
            lithiumInsight: PaletteOptions['primary'];
            strontiumStrategy: PaletteOptions['primary'];
            copperCommunities: PaletteOptions['primary'];
        };
        core?: PaletteCore;
        linearGradients?: PaletteLinearGradients;
    }

    interface TypeBackground {
        paper: string;
        default: string;
    }
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        bodyLarge: React.CSSProperties;
        bodyLargeBold: React.CSSProperties;
        bodyM: React.CSSProperties;
        bodyMM: React.CSSProperties;
        bodyMB: React.CSSProperties;
        bodyS: React.CSSProperties;
        bodySM: React.CSSProperties;
        bodySB: React.CSSProperties;
        bodyXS: React.CSSProperties;
        bodyXSB: React.CSSProperties;
        bodyXXS: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        bodyLarge?: React.CSSProperties;
        bodyLargeBold?: React.CSSProperties;
        bodyM?: React.CSSProperties;
        bodyMM?: React.CSSProperties;
        bodyMB?: React.CSSProperties;
        bodyS?: React.CSSProperties;
        bodySM?: React.CSSProperties;
        bodySB?: React.CSSProperties;
        bodyXS?: React.CSSProperties;
        bodyXSB?: React.CSSProperties;
        bodyXXS?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        bodyLarge: true;
        bodyLargeBold: true;
        bodyM: true;
        bodyMM: true;
        bodyMB: true;
        bodyS: true;
        bodySM: true;
        bodySB: true;
        bodyXS: true;
        bodyXSB: true;
        bodyXXS: true;
    }
}

const createTheme = (): Theme => {
    let theme = createMuiTheme(createBaseOptions());

    theme = responsiveFontSizes(theme);

    return theme;
};

export const theme = createTheme();
