import { createComponents } from './create-components';

import type { ThemeOptions } from '@mui/material/styles/createTheme';

import { createPalette } from '../palette/create-palette';
import { createShadows } from '../shadows/create-shadows';
import { createTypography } from './create-typography';

export const createOptions = (): ThemeOptions => {
    return {
        breakpoints: {
            values: {
                xs: 0,
                sm: 375, // 600 overridden standard value
                md: 744, // 900 overridden standard value
                lg: 1200, // We'll ignore this as we go straight to XL from Tablet
                xl: 1440,
            },
        },
        components: createComponents(),
        shape: {
            borderRadius: 0,
        },
        typography: createTypography(),
        palette: createPalette(),
        shadows: createShadows(),
    };
};
