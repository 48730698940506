import { FC, InputHTMLAttributes } from 'react';

import classNames from 'classnames';

import WarningIcon from '../Icons/WarningIcon';
import './TextInput.scss';

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    error?: string;
    theme?: 'light' | 'dark';
};

export const TextInput: FC<TextInputProps> = ({
    className,
    placeholder,
    type,
    label,
    error,
    value,
    onChange,
    name,
    onBlur,
    onFocus,
    disabled,
    theme,
}) => {
    const classes = classNames(
        'chw-text-input-wrapper',
        {
            'dark-theme': !theme || theme === 'dark',
            'light-theme': theme === 'light',
            'has-error': error,
        },
        className
    );

    return (
        <div className={classes} data-testid="chw-text-input-wrapper">
            {label && (
                <label htmlFor={name} data-testid={`label-${name}`}>
                    {label}
                </label>
            )}
            <input
                id={name}
                name={name}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                data-testid={`input-${name}`}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
            />
            {error && (
                <div
                    className="chw-input-error-wrapper"
                    data-testid="chw-text-input-error"
                >
                    <p className="chw-input-error">
                        <WarningIcon />
                        <span>{error}</span>
                    </p>
                </div>
            )}
        </div>
    );
};
