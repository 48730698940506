import { useCallback, useMemo } from 'react';

import {
    Auth0ContextInterface,
    OAuthError,
    User,
    useAuth0,
} from '@auth0/auth0-react';
import { matchPath } from 'react-router-dom';

import { OpenAPI, UserService } from '../services';

import { Pages, UnauthenticatedPages } from '../domain/Pages';

import { UserEntity } from '../snowplow-tracking/types';

type UseAuth = {
    userId: string;
    user?: UserData;
    isAdminUser: boolean;
    destroySession: () => Promise<unknown>;
    makeRequest: <Returned>(fn: () => void) => Promise<Returned>;
} & Auth0ContextInterface<User>;

export type UserData = UserEntity & {
    sales_id?: string;
};

const baseUrl = 'https://www.crugroup.com/';

/**
 * Return user to previous page after successful login.
 * Fallback to /analysis if previous page is /logged-out, callback/ etc
 */
export const getLoginReturnToUrl = (pathname: string): string => {
    const unauthenticatedPages = Object.values(UnauthenticatedPages);
    const isUnauthenticatedPage = unauthenticatedPages.some((page) =>
        matchPath(page, pathname)
    );
    if (isUnauthenticatedPage) {
        return Pages.analysis;
    }
    return pathname;
};

export const useAuth = (): UseAuth => {
    const auth0 = useAuth0();

    /**
     * Attempt to get auth0 access token.
     * Redirect user to auth0 login on failure
     */
    const getAccessToken = useCallback(async (): Promise<string> => {
        try {
            const token = await auth0.getAccessTokenSilently();
            OpenAPI.TOKEN = token;
            return token;
        } catch (e) {
            const err = e as OAuthError;
            console.error(err.error);

            // redirect to auth0 login
            auth0.loginWithRedirect({
                appState: {
                    returnTo: getLoginReturnToUrl(window.location.pathname),
                    queryString: window.location.search,
                },
            });

            throw err;
        }
    }, [auth0.getAccessTokenSilently]);

    /**
     * Check we have a valid access token for making specified API request
     */
    const makeRequest = useCallback(
        async <Returned>(fn: () => void): Promise<Returned> => {
            await getAccessToken();
            return fn() as unknown as Returned;
        },
        [getAccessToken]
    );

    const userId: string = useMemo(() => {
        return auth0.user?.[baseUrl]?.tracking_id;
    }, [auth0.user]);

    const user = useMemo<UserEntity | undefined>(() => {
        if (!auth0.user) {
            return undefined;
        }
        const userData = auth0.user?.[baseUrl] as {
            email: string;
            first_name: string;
            last_names: string;
            sales_id?: string;
            tracking_id: string;
            roles: string[];
            sub_tier: null | 'Basic' | 'Classic' | 'Premium';
        };
        return {
            sales_id: userData?.sales_id,
            tracking_id: userData?.tracking_id,
            cruonline_user_id: auth0.user?.sub?.split('|')[1] || '',
            a_codes: userData?.roles,
            subscription_tier: userData?.sub_tier,
        };
    }, [auth0.user]);

    const isAdminUser: boolean = useMemo(() => {
        const roles = auth0.user?.[baseUrl]?.roles as string[] | undefined;
        return (roles || []).includes('A999');
    }, [auth0.user]);

    const destroySession = useCallback(async (): Promise<void> => {
        await makeRequest(UserService.destroySession);
        auth0.logout({
            logoutParams: {
                returnTo: `${window.location.origin}${UnauthenticatedPages.loggedOut}`,
            },
        });
    }, [makeRequest]);

    return {
        ...auth0,
        userId,
        user,
        isAdminUser,
        destroySession,
        makeRequest,
    };
};
