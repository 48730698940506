import { FC } from 'react';

import { useFeedbackFormContext } from '../../hooks/useFeedbackFormContext';

import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';
import content from './content';

export type SubscriptionExpiryModalProps = {
    modalOpen: boolean;
    toggleModal: (open: boolean) => void;
    componentId: string;
    feedbackRequired: boolean;
};

const { modal: modalContent } = content;

export const SubscriptionExpiryModal: FC<SubscriptionExpiryModalProps> = ({
    modalOpen,
    toggleModal,
    componentId,
    feedbackRequired,
}) => {
    const { toggleFeedbackForm } = useFeedbackFormContext();

    const onCloseModal = () => {
        toggleModal(false);

        if (feedbackRequired) {
            toggleFeedbackForm(true);
        }
    };

    const text = feedbackRequired
        ? modalContent.declinedText
        : modalContent.acceptedText;

    return (
        <Modal
            open={modalOpen}
            onClose={onCloseModal}
            content={<p>{text}</p>}
            variant="standard"
            actions={
                <Button
                    theme="light"
                    id={`${componentId}-modal-confirmation-button`}
                    onClick={onCloseModal}
                    testId={`${componentId}-modal-confirmation-button`}
                >
                    {content.modal.btnText}
                </Button>
            }
        />
    );
};
