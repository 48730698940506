import {
    ChangeEvent,
    FC,
    FormEvent,
    useEffect,
    useMemo,
    useState,
} from 'react';

import classNames from 'classnames';
import DOMPurify from 'dompurify';

import { FeedbackService, UserFeedbackViewModel } from '../../services';

import { useDeviceContext } from '../../hooks/useDeviceContext';

import { Button } from '../Button/Button';
import CloseIcon from '../Icons/CloseIcon';
import { Modal } from '../Modal/Modal';
import { RadioGroup } from '../RadioGroup/RadioGroup';
import {
    FEEDBACK_RATING_LIST,
    FEEDBACK_TARGET_LIST,
    FeedbackInputItem,
} from './data';

type FeedbackFormProps = {
    formVisible: boolean;
    hideForm: () => void;
    disabled?: boolean;
};

export const FeedbackForm: FC<FeedbackFormProps> = ({
    formVisible,
    hideForm,
    disabled,
}) => {
    const { smallDevice } = useDeviceContext();
    const [ratings, setRatings] = useState(FEEDBACK_RATING_LIST);
    const [feedbackTargets, setTargets] = useState(FEEDBACK_TARGET_LIST);
    const [details, setDetails] = useState('');
    const [pending, setPending] = useState(false);
    const [succeed, setSucceed] = useState(false);

    useEffect(() => {
        if (!formVisible) {
            setPending(formVisible);
            setSucceed(formVisible);
            setRatings(FEEDBACK_RATING_LIST);
            setTargets(FEEDBACK_TARGET_LIST);
            setDetails('');
        }
    }, [formVisible]);

    const classes = classNames('feedback-form__wrapper', {
        activated: formVisible,
    });

    const validForm = useMemo(() => {
        if (
            !ratings.find((ite) => ite.value) ||
            !feedbackTargets.find((ite) => ite.value)
        ) {
            return false;
        }
        return true;
    }, [ratings, feedbackTargets, details]);

    const onChangeFeedbackText = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setDetails(DOMPurify.sanitize(evt.target.value));
    };

    const onSubmitForm = async (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        const ratingValue = ratings.find((ite) => ite.value)?.id ?? '';
        const feedbackValue =
            feedbackTargets.find((ite) => ite.value)?.id ?? '';
        const payload: UserFeedbackViewModel = {
            details,
            rating: ratingValue,
            feedbackTarget: feedbackValue,
            url: window.location.href,
        };

        try {
            setPending(true);
            const res = await FeedbackService.send(payload);
            if (res === 'Success') {
                setSucceed(true);
            }
        } catch (error) {
            console.log(error);
            setSucceed(false);
        } finally {
            setPending(true);
        }
    };

    const feedBackFormContent = (
        <div className="feedback-form-content">
            <form onSubmit={onSubmitForm}>
                <fieldset disabled={disabled}>
                    <RadioGroup<FeedbackInputItem>
                        items={ratings}
                        groupId="feedback-rating-group"
                        onChange={setRatings}
                    />
                    <div className="feedback-textbox">
                        <label
                            data-testid="feedback-text-area"
                            className="feedback-text-label"
                            htmlFor="feedback-text-area"
                        >
                            Please give some details
                        </label>
                        <textarea
                            id="feedback-text-area"
                            name="feedback-details"
                            rows={5}
                            value={details}
                            onChange={onChangeFeedbackText}
                        />
                    </div>
                    <RadioGroup<FeedbackInputItem>
                        items={feedbackTargets}
                        groupId="feedback-target-group"
                        onChange={setTargets}
                    />
                    <Button
                        disabled={pending || !validForm}
                        testId="feedback-submit-button"
                        theme="light"
                    >
                        Send
                    </Button>
                </fieldset>
            </form>
        </div>
    );

    const feedbackSubmitResult = (
        <div
            className="feedback-form-content"
            data-testid="feedback-form-submit-result"
        >
            <p className="feedback-form-content__description">
                A member of our team will be in touch within 7 days. <br />
                If we need to further discuss your feedback, we will contact you
                via your registered email address with CRU.
            </p>
        </div>
    );

    const allFeedBackContent = succeed
        ? feedbackSubmitResult
        : feedBackFormContent;

    const titleText = succeed
        ? 'Thank you for submitting your feedback on CRU Online.'
        : 'How was your experience?';

    if (smallDevice) {
        return (
            <Modal
                open={formVisible}
                onClose={hideForm}
                title={titleText}
                content={allFeedBackContent}
            />
        );
    }

    return (
        <div className={classes} data-testid="feedback-form-container-desktop">
            <button
                className="feedback-form__close-x"
                onClick={hideForm}
                data-testid="feedback-form__close-x"
                aria-label="Close the feedback form"
            >
                <CloseIcon width={18} height={18} />
            </button>
            <button
                className="feedback-form__trigger"
                onClick={hideForm}
                data-testid="feedback-form-close-desktop"
            >
                <span>Feedback</span>
            </button>
            <div className="feedback-form-container">
                <h3 className="feedback-form-title">{titleText}</h3>
                {allFeedBackContent}
            </div>
        </div>
    );
};
