import { FC } from 'react';

import { Pages } from '../../domain/Pages';

import { useAuth } from '../../hooks/useAuth';

import { AuthFlowLayout } from '../../components/Layout/AuthFlowLayout/AuthFlowLayout';
import footerContent from '../../components/Layout/Footer/FooterContent';

const content = {
    pageTitle: 'CRU you are now logged out',
    heading: 'Logout',
    textBlock: (
        <>
            <p>
                Thank you for using our platform. You have been successfully
                logged out and you can now close this page.
            </p>
            <p>
                If you need to access your account again, please log in using
                your credentials.
            </p>
        </>
    ),
};

export const LoggedOutPage: FC = () => {
    const { loginWithRedirect } = useAuth();

    return (
        <AuthFlowLayout
            pageViewData={{
                title: content.pageTitle,
            }}
            heading={content.heading}
            textBlock={content.textBlock}
            actionBarContent={
                <button
                    className="text-sm font-bold text-brand-azure-40"
                    data-test-id="login-button"
                    onClick={() =>
                        loginWithRedirect({
                            appState: {
                                returnTo: Pages.analysis,
                            },
                        })
                    }
                >
                    Log in again
                </button>
            }
            footerLinks={footerContent.links}
        />
    );
};
