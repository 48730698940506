import { FC } from 'react';

import { Oval } from 'react-loader-spinner';

import './Spinner.scss';

const Spinner: FC = () => (
    <div className="chw-app-spinner" data-testid="spinner">
        <Oval
            color="#007dbb"
            secondaryColor="#b5dcf3"
            height={100}
            width={100}
        />
        <div className="chw-app-spinner-overlay" />
    </div>
);

export default Spinner;
