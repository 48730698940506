import React, { ReactNode } from 'react';

import classNames from 'classnames';

import { TrackPageViewEvent } from '../../snowplow-tracking/types';
import { PageViewTracker } from '../PageViewTracker/PageViewTracker';
import { ProductToursWrapper } from '../ProductTours/ProductTours';
import { mainContentId } from '../SkipLink/SkipLink';
import Spinner from '../Spinner/Spinner';
import './AppLayout.scss';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import { PageMeta } from './PageMeta';

export type LayoutProps = {
    headerContent?: ReactNode;
    middleContent?: ReactNode;
    rightContent?: ReactNode | null;
    bottomContent?: ReactNode;
    children?: React.ReactNode;
    showSpin?: boolean;
    hideSubTitleOnMobile?: boolean;
    pageName?: string;
    background?: 'transparent' | 'light';
    customClass?: string;
    isMounted?: boolean;
    pageViewData?: TrackPageViewEvent;
    hasContentStructure?: boolean;
};

const AppLayout: React.FC<LayoutProps> = ({
    children,
    middleContent,
    headerContent,
    rightContent,
    bottomContent,
    showSpin,
    hideSubTitleOnMobile,
    pageName,
    background = 'transparent',
    customClass,
    isMounted = true,
    pageViewData,
    hasContentStructure = true,
}) => {
    const bodyClassName = classNames(
        'chw-body',
        { [`chw-body--${background}`]: background },
        { [`chw-body--full-width`]: !rightContent },
        customClass
    );
    return (
        <>
            {!showSpin && <PageMeta title={pageViewData?.title ?? ''} />}

            {!showSpin && pageViewData && (
                <PageViewTracker pageViewData={pageViewData} />
            )}

            <Header />

            {hasContentStructure ? (
                <>
                    <main
                        className={bodyClassName}
                        tabIndex={-1}
                        id={mainContentId}
                    >
                        {!hideSubTitleOnMobile && (
                            <div className="mobile-view-page-title">
                                <h1>{pageName}</h1>
                            </div>
                        )}
                        {headerContent && (
                            <div className="chw-content-header">
                                {headerContent}
                            </div>
                        )}
                        <div className="chw-content-body">
                            <div className="chw-content-body__middle">
                                {middleContent ?? children}
                            </div>
                            {rightContent && (
                                <div className="chw-content-body__right">
                                    {rightContent}
                                </div>
                            )}
                        </div>
                        {bottomContent && (
                            <div className="chw-content__fullwidth">
                                <div className="chw-content__fullwidth-inner">
                                    {bottomContent}
                                </div>
                            </div>
                        )}
                    </main>
                    {!showSpin && <Footer />}
                </>
            ) : (
                <main tabIndex={-1} id={mainContentId}>
                    {children}
                </main>
            )}

            {showSpin && <Spinner />}

            <ProductToursWrapper pageReady={isMounted && !showSpin} />
        </>
    );
};

export default AppLayout;
