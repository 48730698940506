import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import DOMPurify from 'dompurify';

import { MobileMenuContext } from '../../../../../context/MobileMenuContext';

import { useDeviceContext } from '../../../../../hooks/useDeviceContext';
import {
    EventName,
    useEventAnalytics,
} from '../../../../../hooks/useEventAnalytics';
import { useFiltersAndPaging } from '../../../../../hooks/useFiltersAndPaging';

import CloseIcon from '../../../../Icons/CloseIcon';
import SearchIcon from '../../../../Icons/SearchIcon';
import './SearchWidget.scss';

export const SearchWidget: React.FC = () => {
    const { queries, setQueries } = useFiltersAndPaging();
    const { smallDevice } = useDeviceContext();
    const { activatedMobileMenu, onToggleMobileMenu } =
        useContext(MobileMenuContext);
    const { trackEvent } = useEventAnalytics();

    const [searchTerm, setSearchTerm] = useState('');

    const handleOpenMobileMenu = () => {
        if (activatedMobileMenu === 'search') {
            onToggleMobileMenu();
        } else {
            onToggleMobileMenu('search');
        }
    };

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value: searchTermValue } = evt.target;
        setSearchTerm(DOMPurify.sanitize(searchTermValue));
    };

    const handleOnSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (searchTerm) {
            setQueries({ term: searchTerm }, { fromSearch: true });
            trackEvent({
                event: EventName.SiteSearch,
                search_term: searchTerm,
            });
        }
    };

    useEffect(() => {
        setSearchTerm(queries.term);
    }, [queries.term]);

    return (
        <div id="chw-header-search-widget">
            {!smallDevice && (
                <form
                    onSubmit={handleOnSubmit}
                    className="search-input-wrapper"
                    data-testid="search-form"
                >
                    <label className="sr-only" htmlFor="search">
                        Search CRU
                    </label>
                    <input
                        type="text"
                        data-testid="search-input"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleChange}
                        id="search"
                        className="search-input"
                    />
                    <button
                        className="search-label"
                        type="submit"
                        data-testid="search-button"
                    >
                        <SearchIcon />
                        <span className="sr-only">Submit</span>
                    </button>
                </form>
            )}
            {smallDevice && (
                <div
                    className={classNames('search-mobile-widget', {
                        'is-open': activatedMobileMenu === 'search',
                    })}
                >
                    <form
                        onSubmit={handleOnSubmit}
                        className="search-input-mobile-wrapper"
                        data-testid="search-form"
                    >
                        <label className="sr-only" htmlFor="search">
                            Search CRU
                        </label>
                        <input
                            type="text"
                            data-testid="search-input"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleChange}
                            id="search"
                            className="search-input"
                        />
                        <button
                            className="search-label"
                            type="submit"
                            data-testid="search-button"
                        >
                            <SearchIcon />
                            <span className="sr-only">Submit</span>
                        </button>
                    </form>

                    <button
                        aria-label="search input"
                        className="search-input-mobile-trigger"
                        onClick={handleOpenMobileMenu}
                    >
                        <SearchIcon />
                        <CloseIcon />
                    </button>
                </div>
            )}
        </div>
    );
};
