/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplicationUser } from '../models/ApplicationUser';
import type { ChangePasswordPostModel } from '../models/ChangePasswordPostModel';
import type { UserAccountDetails } from '../models/UserAccountDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param token
     * @returns ApplicationUser Success
     * @throws ApiError
     */
    public static getApiUser(
        token: string,
    ): CancelablePromise<ApplicationUser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User',
            query: {
                'token': token,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns UserAccountDetails Success
     * @throws ApiError
     */
    public static getAccountDetails(): CancelablePromise<UserAccountDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetAccountDetails',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public static changePassword(
        requestBody?: ChangePasswordPostModel,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/ChangePassword',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static destroySession(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/User/DestroySession',
        });
    }

    /**
     * @param force
     * @returns string Success
     * @throws ApiError
     */
    public static getCookie(
        force: boolean = false,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetCookie',
            query: {
                'force': force,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}