export enum Pages {
    login = '/login',
    account = '/account-settings',
    search = '/search',
    mycru = '/mycru',
    savedSearches = '/mycru?tab=searches',
    analysis = '/analysis',
    analysisArticle = '/analysis/article/:articleId',
    help = '/help',
    downloads = '/downloads',
    prices = '/prices',
    pricesBundle = '/prices/:bundleName',
    priceDetail = '/price',
    notifications = '/notifications',
    notificationsArticle = '/notifications/article/:articleId',
    methodologies = '/methodologies',
    terms = '/termsandconditions',
    chatAi = '/chat-ai',
}

export enum UnauthenticatedPages {
    authCallback = '/callback',
    logout = '/logout',
    loggedOut = '/logged-out',
}
