import { useContext } from 'react';

import {
    FeedbackFormContext,
    FeedbackFormContextState,
} from '../context/FeedbackFormContext';

export const useFeedbackFormContext = (): FeedbackFormContextState => {
    const context = useContext(FeedbackFormContext);

    return {
        ...context,
    };
};
