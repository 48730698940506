import { FC } from 'react';

import { Button } from '../Button/Button';

export type ScheduleTourButtonProps = {
    tourUrl?: string;
};

export const ScheduleTourButton: FC<ScheduleTourButtonProps> = ({
    tourUrl,
}) => {
    if (!tourUrl) {
        return null;
    }
    return (
        <Button href={tourUrl} target="_blank" variant="outline" theme="light">
            Book a Meeting
        </Button>
    );
};
