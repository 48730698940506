import { useQuery } from '@tanstack/react-query';

import { LinkService } from '../../services';

import { QueryKeys } from '../../domain/QueryKeys';

import { useAuth } from '../useAuth';

export const useRedirectToUrlService = (urlPart: string) => {
    const { makeRequest } = useAuth();
    return useQuery(
        [QueryKeys.redirectToUrl, urlPart],
        () => makeRequest(() => LinkService.redirectToUrl(urlPart)),
        {
            useErrorBoundary: true,
            enabled: !!urlPart,
        }
    );
};
