import {
    FC,
    ReactNode,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useLocation } from 'react-router-dom';

import { disableScroll, enableScroll } from '../util/disableScroll';

import { useHeader } from '../hooks/useHeader';

type ProviderProps = {
    children: ReactNode;
};

type MobileMenuItemTypes = 'burger' | 'notification' | 'user' | 'search';

type MobileMenuContextState = {
    activatedMobileMenu?: MobileMenuItemTypes;
    onToggleMobileMenu: (type?: MobileMenuItemTypes) => void;
};

export const MobileMenuContext = createContext<MobileMenuContextState>({
    onToggleMobileMenu: () => undefined,
});

export const MobileMenuContextProvider: FC<ProviderProps> = ({ children }) => {
    const location = useLocation();
    const [activated, setActivated] = useState<MobileMenuItemTypes>();
    const { addStickyHeader, removeStickyHeader } = useHeader();

    const handleActiveMenu = useCallback((type?: MobileMenuItemTypes) => {
        setActivated(type);
        if (type) {
            addStickyHeader();
            disableScroll();
        } else {
            enableScroll();
            removeStickyHeader();
        }
    }, []);

    useEffect(() => {
        enableScroll();
        removeStickyHeader();
        setActivated(undefined);
    }, [location]);

    const contextValue = useMemo(
        () => ({
            activatedMobileMenu: activated,
            onToggleMobileMenu: handleActiveMenu,
        }),
        [activated, handleActiveMenu]
    );

    return (
        <MobileMenuContext.Provider value={contextValue}>
            {children}
        </MobileMenuContext.Provider>
    );
};
