import { FC, useContext, useMemo } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { HeaderItemModel } from '../../../../../services';

import { Pages, UnauthenticatedPages } from '../../../../../domain/Pages';

import { MobileMenuContext } from '../../../../../context/MobileMenuContext';

import { useDeviceContext } from '../../../../../hooks/useDeviceContext';

import UserIcon from '../../../../Icons/UserIcon';
import { HeaderLink } from '../HeaderLink/HeaderLink';
import {
    HeaderSubMenuCategory,
    SubMenuItemExtend,
} from '../HeaderSubMenuCategory/HeaderSubMenuCategory';
import { ImpersonateUserButton } from '../ImpersonateUserButton/ImpersonateUserButton';
import { LogoutButton } from '../LogoutButton/LogoutButton';
import { ProductTourButton } from '../ProductTourButton/ProductTourButton';
import './UserMoniker.scss';

const subMenu: SubMenuItemExtend[] = [
    {
        link: `${Pages.account}?tab=account-details`,
        title: 'Account Settings',
        usage: null,
    },
    {
        link: `${Pages.account}?tab=email-preferences`,
        title: 'Email Preferences',
        usage: null,
    },
    {
        link: Pages.methodologies,
        title: 'Price Methodologies',
        usage: null,
    },
    {
        title: 'Product Tour',
        component: ProductTourButton,
        usage: null,
    },
    {
        link: `${Pages.help}?tab=user-guides`,
        title: 'Help',
        usage: null,
    },
    {
        title: 'Impersonate User',
        component: ImpersonateUserButton,
        usage: null,
    },
    {
        title: 'Logout',
        component: LogoutButton,
        usage: null,
        link: UnauthenticatedPages.loggedOut,
    },
];

const userMenuLinks: Partial<HeaderItemModel> = {
    subMenu: [
        {
            title: '',
            link: '',
            items: subMenu,
        },
    ],
};

type UserMonikerProps = {
    userName?: string;
};

export const UserMoniker: FC<UserMonikerProps> = ({ userName }) => {
    const { smallDevice } = useDeviceContext();
    const { activatedMobileMenu, onToggleMobileMenu } =
        useContext(MobileMenuContext);

    const location = useLocation();

    const isActive = useMemo(() => {
        const fullUrl = location.pathname + location.search;
        return subMenu.some(({ link = '' }) => fullUrl === link);
    }, [location.pathname, location.search]);

    if (!userName) {
        return null;
    }

    const handleOpenMobileMenu = () => {
        if (activatedMobileMenu !== 'user') {
            onToggleMobileMenu('user');
        } else {
            onToggleMobileMenu();
        }
    };

    return (
        <div id="chw-header-user-moniker">
            {!smallDevice ? (
                <div className="chw-header-links">
                    <HeaderLink
                        {...userMenuLinks}
                        index={0}
                        isActive={isActive}
                        isEnabled
                    >
                        <span>{userName}</span>
                    </HeaderLink>
                </div>
            ) : (
                <div
                    className={classNames('user-menu-mobile', {
                        'is-open': activatedMobileMenu === 'user',
                    })}
                >
                    <button
                        className="user-menu-mobile__button"
                        onClick={handleOpenMobileMenu}
                    >
                        <UserIcon /> <span className="sr-only">User menu</span>
                    </button>
                    <div className="user-menu-mobile-list-container">
                        <div className="user-menu-mobile-list-container__inner">
                            {(userMenuLinks.subMenu || []).map((subMenu, i) => (
                                <HeaderSubMenuCategory {...subMenu} key={i} />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
