import {
    UseMutationResult,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';

import {
    ApiError,
    ImpersonationInfoViewModel,
    ImpersonationService,
    OpenAPI,
} from '../../services';

import snowplowTracking from '../../snowplow-tracking';
import { useAuth } from '../useAuth';

type SubmitImpersonateUserResult = UseMutationResult<
    ImpersonationInfoViewModel,
    ApiError,
    string
>;

type HeaderKeyName = keyof typeof OpenAPI.HEADERS;

type UseImpersonateUserService = {
    impersonateUser: SubmitImpersonateUserResult;
    resetImpersonateUser: (customHeaderName: string) => void;
};

export const useImpersonateUserService = (): UseImpersonateUserService => {
    const { makeRequest } = useAuth();
    const queryClient = useQueryClient();

    const impersonateUser: SubmitImpersonateUserResult = useMutation(
        (user: string) =>
            makeRequest(() =>
                ImpersonationService.postApiImpersonate({ user })
            ),
        {
            useErrorBoundary: (error) => error.status >= 500,
            onSuccess: (apiResponse) => {
                // add custom header to OpenApi.headers
                const key = apiResponse.headerName || '';
                const value = apiResponse.token || '';

                OpenAPI.HEADERS = { ...OpenAPI.HEADERS, [key]: value };

                snowplowTracking.disableTracking();

                // clear in the cache so that data is fetched as impersonated user
                queryClient.refetchQueries();
            },
        }
    );

    const resetImpersonateUser = (customHeaderName: string) => {
        if (customHeaderName && OpenAPI.HEADERS) {
            // remove custom header from OpenApi.headers
            const newHeaders = { ...OpenAPI.HEADERS };
            delete newHeaders[customHeaderName as HeaderKeyName];
            OpenAPI.HEADERS = newHeaders;

            snowplowTracking.enableTracking();

            // clear in the cache so that data is fetched as the logged-in user
            queryClient.refetchQueries();
        }
    };

    return {
        impersonateUser,
        resetImpersonateUser,
    };
};
