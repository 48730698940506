import { FC, memo, useEffect, useMemo } from 'react';

import Joyride, { CallBackProps, Step } from 'react-joyride';
import { matchPath, useLocation } from 'react-router-dom';

import { CampaignItem, UserTourStep } from '../../services';

import { Pages } from '../../domain/Pages';

import { getCampaignByName } from '../../util/dataLabUtils';

import { ProductTour } from '../../context/ProductToursContextProvider';

import {
    useGetCampaigns,
    useUpdateCampaign,
} from '../../hooks/data/useCampaigns';
import { useDeviceContext } from '../../hooks/useDeviceContext';
import { useProductToursContext } from '../../hooks/useProductToursContext';

import { ProductToursTooltip } from '../ProductToursTooltip/ProductToursTooltip';

export type ProductToursProps = {
    pathname: string;
    pageReady: boolean;
    smallDevice?: boolean;
};

// Tours that are triggered by a specific campaign data being in the response
const toursTriggeredByCampaigns: { [key: string]: string } = {
    headerMyPricesTour: 'headerMyPricesCampaign', // to show tour in header, to drive users to bookmark prices
    articlePriceCardTour: 'articlePriceCardCampaign', // to show tour on 'Price Detail' card button, to drive users to Price Detail page
    myPricesPriceCardTour: 'myPricesPriceCardCampaign', // to show tour on 'MyCru My Prices' card button, to drive users to Price Detail page
};

export const campaignTourNames = Object.keys(toursTriggeredByCampaigns);
const tourCampaignNames = Object.values(toursTriggeredByCampaigns);
type CampaignTourName = (typeof campaignTourNames)[number];
type TourCampaignName = (typeof tourCampaignNames)[number];

// Campaign Tours are always enabled. They are shown ONLY based on presence of Campaign data.
// EXCEPT headerMyPricesTour. Since it appears first, it MUST then be set to enabled:false so that it moves onto the next Tour on the page.
const isEnabledOrPriceCardTour = (
    tourName: string | null | undefined,
    enabled: boolean
) => {
    const priceCardTourNames = campaignTourNames.slice(1);
    return enabled || (tourName && priceCardTourNames.includes(tourName));
};

// Allow tour to have multiple paths separated by "|" (articlePriceCardTour is for all analysis and notification article pages)
const tourPageMatchesCurrentRoute = (
    page: string | null | undefined,
    pathname: string
) => {
    const pageArr = page?.split('|');
    if (!pageArr) return false;
    for (const pageName of pageArr) {
        const pageIndex = Object.keys(Pages).indexOf(pageName as Pages);
        if (pageIndex === -1) {
            continue;
        }
        const pageRoute = Object.values(Pages)[pageIndex];
        const m = matchPath({ path: pageRoute, end: false }, pathname);
        if (m) return true;
    }

    return false;
};

// checks if target of first Tour Step is actually in the page before making any updates.
export const isTargetInPage = (tourSteps: UserTourStep[] | undefined) => {
    const step0Target = tourSteps?.[0]?.target;
    const foundElements = step0Target && document.querySelectorAll(step0Target);
    return foundElements && foundElements.length > 0;
};

export const getActiveToursForPage = (
    tours: ProductTour[],
    pathname: string,
    campaignsData?: CampaignItem[] | undefined
): ProductTour[] => {
    let toursForPage = tours.filter(({ enabled, page, tourName }) => {
        if (isEnabledOrPriceCardTour(tourName, enabled)) {
            //page = '*' show tour on all pages
            if (page === '*') return true;

            // otherwise 'page' must match current pathname
            return tourPageMatchesCurrentRoute(page, pathname);
        }
    });

    // store the CampaignData for each Tour
    const tourCampaignData: {
        [key: CampaignTourName]: CampaignItem | undefined;
    } = {};

    for (const tour of toursForPage) {
        const thisTourName = tour.tourName;
        if (!thisTourName) continue; // should never happen. Tours should be named.

        // get data for Campaign that triggers each Tour
        const campaignNameForTour: TourCampaignName | undefined =
            toursTriggeredByCampaigns[thisTourName];

        const campaignData: CampaignItem | undefined = getCampaignByName(
            campaignNameForTour,
            campaignsData
        );
        tourCampaignData[thisTourName] = campaignData;
        tour.campaignId = campaignData?.id;

        // If the current Tour is triggered by a Campaign, but Campaign has no data, remove the Tour.
        if (campaignNameForTour && !campaignData) {
            toursForPage = toursForPage.filter(
                (tour) => tour.tourName !== thisTourName
            );
        }
    }
    return toursForPage;
};

export const ProductTours: FC<ProductToursProps> = ({
    pathname,
    pageReady,
    smallDevice,
}) => {
    const { data: campaignsData } = useGetCampaigns();
    const { setCampaign } = useUpdateCampaign();
    const { tours, startTour, stopTour, shouldRun } = useProductToursContext();

    // all tours which are enabled to be shown on the current page
    const activeProductTours = useMemo(() => {
        return getActiveToursForPage(tours, pathname, campaignsData);
    }, [tours, pathname, campaignsData]);

    // Tour that is first up on the page
    const productTour = useMemo(() => {
        return activeProductTours.length > 0 ? activeProductTours[0] : null;
    }, [activeProductTours]);
    const tourName: string =
        activeProductTours.length > 0
            ? activeProductTours[0].tourName ?? ''
            : '';

    useEffect(() => {
        if (
            pageReady &&
            activeProductTours.length > 0 &&
            isTargetInPage(productTour?.tourSteps)
        ) {
            startTour(tourName || '');
            // If Tour triggered by a campaign, POST to update the Campaign as seen.
            if (tourName && campaignTourNames.includes(tourName)) {
                setCampaign.mutate({
                    campaignId: productTour?.campaignId as number,
                    notes: window.location.href,
                });
            }
        }
    }, [pageReady, activeProductTours.length]);

    const callback = ({ status, lifecycle }: CallBackProps) => {
        if (
            lifecycle === 'complete' &&
            (status === 'skipped' || status === 'finished') &&
            isTargetInPage(productTour?.tourSteps)
        ) {
            stopTour(tourName || ''); // update Tour to enabled: false
        }
    };

    if (!productTour || smallDevice) {
        return null;
    }

    return (
        <Joyride
            callback={callback}
            continuous={true}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            steps={productTour.tourSteps as unknown as Step[]}
            scrollOffset={60}
            scrollDuration={0}
            run={shouldRun}
            tooltipComponent={ProductToursTooltip}
            disableOverlayClose
            styles={{
                options: {
                    arrowColor: '#00519e',
                },
            }}
        />
    );
};
const ProductToursMemoized = memo(ProductTours);

export const ProductToursWrapper: FC<{ pageReady: boolean }> = ({
    pageReady,
}) => {
    const location = useLocation();
    const { smallDevice } = useDeviceContext();

    return (
        <ProductToursMemoized
            pathname={location.pathname}
            pageReady={pageReady}
            smallDevice={smallDevice}
        />
    );
};
