import React, { FC } from 'react';

import classNames from 'classnames';

export type PageHeaderControlsProps = {
    tabControls?: React.JSX.Element;
    endControls?: React.JSX.Element;
};

const tabControlsComp = (
    tabControls?: React.JSX.Element,
    endControls?: React.JSX.Element
) => {
    // if endControls is empty, tabControls component must be full width
    const tabClass = endControls ? '' : 'w-full';
    return (
        <div
            className={classNames(
                `${tabClass} flex justify-start gap-2 overflow-auto`
            )}
        >
            {tabControls}
        </div>
    );
};

const endControlsComp = (
    tabControls?: React.JSX.Element,
    endControls?: React.JSX.Element
) => {
    // if tabControls has tabs, indent endControls on small screens, so they align with the tab Labels when they wrap above
    const indentButtonsIfTabsExist = tabControls ? 'ml-2' : '';
    return (
        <div
            className={classNames(
                `flex justify-start gap-2 py-2 md:justify-end md:py-0 lg:justify-end lg:py-0 xl:justify-end xl:py-0 ${indentButtonsIfTabsExist}`
            )}
        >
            {endControls}
        </div>
    );
};

export const PageHeaderControls: FC<PageHeaderControlsProps> = ({
    tabControls,
    endControls,
}) => {
    return tabControls || endControls ? (
        <div className="no-print flex w-full border-b border-b-neutral-200 bg-neutral-50 pt-3">
            <div className="pageheadercontrols__inner mx-auto flex w-full flex-col-reverse justify-between px-8 sm:max-w-screen-sm md:max-w-screen-md md:flex-row lg:max-w-screen-lg xl:max-w-screen-xl">
                {tabControls && tabControlsComp(tabControls, endControls)}
                {endControls && endControlsComp(tabControls, endControls)}
            </div>
        </div>
    ) : null;
};
