import {
    PyramidService,
    UserDatatableDownloadModel,
    UserDocumentViewerModel,
    UserPageViewModel,
} from '../../services';

import { useAuth } from '../useAuth';

type UsePyramidServiceResult = {
    logPageView: (payload: UserPageViewModel) => void;
    logDatatableDownload: (
        payload: UserDatatableDownloadModel
    ) => Promise<unknown>;
    logDocumentView: (payload: UserDocumentViewerModel) => void;
};

export const usePyramidService = (): UsePyramidServiceResult => {
    const { makeRequest } = useAuth();

    const logPageView = (payload: UserPageViewModel) =>
        makeRequest(() => PyramidService.logPageView(payload));

    const logDatatableDownload = (payload: UserDatatableDownloadModel) =>
        makeRequest(() => PyramidService.logDatatableDownload(payload));

    const logDocumentView = (payload: UserDocumentViewerModel) =>
        makeRequest(() => PyramidService.logDocumentView(payload));

    return {
        logPageView,
        logDatatableDownload,
        logDocumentView,
    };
};
