import { FC } from 'react';

type IconProps = {
    width?: string | number;
    height?: string | number;
    fill?: string;
    className?: string;
};

const NotificationIcon: FC<IconProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="currentColor"
            {...props}
        >
            <path
                d="M52.349,19.618c-2.49-1.152-2.585-6.1-2.586-6.22V10.081a7.724,7.724,0,0,0-4.61-7.106,2.915,2.915,0,1,0-5.828,0,7.724,7.724,0,0,0-4.61,7.106V13.4c0,.125-.1,5.069-2.587,6.22a.681.681,0,0,0-.374.759.665.665,0,0,0,.646.534H38.71a3.837,3.837,0,0,0,.981,1.957,3.43,3.43,0,0,0,5.092,0,3.836,3.836,0,0,0,.981-1.957h6.312a.665.665,0,0,0,.646-.534A.681.681,0,0,0,52.349,19.618Zm-3.28-2.368a7.574,7.574,0,0,0,1.158,2.308H34.248a7.573,7.573,0,0,0,1.158-2.308Zm-6.831-15.9a1.6,1.6,0,0,1,1.53,1.18,7.382,7.382,0,0,0-3.061,0A1.6,1.6,0,0,1,42.237,1.354ZM36.034,13.4V10.08a6.205,6.205,0,1,1,12.407,0v3.327c0,.1.365,2.525.628,3.842H35.406C35.669,15.931,36.033,13.506,36.034,13.4Zm6.2,9.245a2.328,2.328,0,0,1-2.171-1.735h4.342A2.328,2.328,0,0,1,42.237,22.647Z"
                transform="translate(-31.737)"
            />
        </svg>
    );
};

export default NotificationIcon;
