import { FC, ReactNode, createContext, useMemo, useState } from 'react';

export type IsAuthenticatedContextProps = {
    isAuthenticated: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    hasUserCookie: boolean;
    setHasUserCookie: (hasUserCookie: boolean) => void;
};

export const IsAuthenticatedContext =
    createContext<IsAuthenticatedContextProps>({
        isAuthenticated: false,
        setIsAuthenticated: () => undefined,
        hasUserCookie: false,
        setHasUserCookie: () => undefined,
    });

type IsAuthenticatedContextProviderProps = {
    children: ReactNode;
};

const IsAuthenticatedContextProvider: FC<
    IsAuthenticatedContextProviderProps
> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [hasUserCookie, setHasUserCookie] = useState(false);

    const contextValue = useMemo(
        () => ({
            isAuthenticated,
            setIsAuthenticated,
            hasUserCookie,
            setHasUserCookie,
        }),
        [isAuthenticated, setIsAuthenticated, hasUserCookie, setHasUserCookie]
    );

    return (
        <IsAuthenticatedContext.Provider value={contextValue}>
            {children}
        </IsAuthenticatedContext.Provider>
    );
};

export default IsAuthenticatedContextProvider;
