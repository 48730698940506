import { FC } from 'react';

import classNames from 'classnames';

import { HeaderItemModel } from '../../../../../services';

import {
    HeaderSubMenuCategory,
    SubMenuItemExtend,
} from '../HeaderSubMenuCategory/HeaderSubMenuCategory';
import { MyPrices } from '../MyPricesLink/MyPrices';
import './HeaderSubMenu.scss';

export const HeaderSubMenu: FC<Partial<HeaderItemModel>> = ({
    subMenu,
    link,
    title,
}) => {
    if (!subMenu || subMenu.length === 0) return null;

    const className = classNames('header-sub-menu', {
        [`grid-cols_${subMenu.length}`]: subMenu.length > 1,
        'header-sub-menu-standard-layout':
            subMenu.length === 1 &&
            subMenu[0].items &&
            subMenu[0].items.length > 10,
    });

    const myPricesTitle = 'My Prices';

    return (
        subMenu && (
            <div className={className} tabIndex={-1}>
                {
                    // tools e.g. don't have grouping, so either render grouping or items directly
                    subMenu.map((subMenuItem, index) => {
                        const to = link
                            ? `${link}${subMenuItem.link}`
                            : subMenuItem.link;

                        // cast items as SubMenuItemExtend[] in order to make use of `component` prop
                        const items: SubMenuItemExtend[] = subMenuItem.items
                            ? [...subMenuItem.items]
                            : [];

                        // insert MyPrices link component into Prices subnav items, only once!
                        if (
                            title === 'Prices' &&
                            items[0].title !== myPricesTitle
                        ) {
                            items.unshift({
                                title: 'My Prices',
                                component: MyPrices,
                            });
                        }
                        return (
                            <HeaderSubMenuCategory
                                key={index}
                                title={subMenuItem.title}
                                link={to}
                                items={items}
                            />
                        );
                    })
                }
            </div>
        )
    );
};
