import { FC } from 'react';

type IconProps = {
    width?: string | number;
    height?: string | number;
    fill?: string;
};

const SearchIcon: FC<IconProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="search-icon"
            {...props}
        >
            <path
                d="M13.572,3A9.457,9.457,0,0,0,4,12.352,9.457,9.457,0,0,0,13.572,21.7a9.671,9.671,0,0,0,5.12-1.461L25.607,27,28,24.662l-6.827-6.653a9.151,9.151,0,0,0,1.971-5.656A9.457,9.457,0,0,0,13.572,3Zm0,2.2a7.223,7.223,0,0,1,7.32,7.152,7.223,7.223,0,0,1-7.32,7.152,7.223,7.223,0,0,1-7.32-7.152A7.223,7.223,0,0,1,13.572,5.2Z"
                transform="translate(-4 -3)"
            />
        </svg>
    );
};

export default SearchIcon;
