import React, { FC } from 'react';

import {
    NavLink,
    NavLinkProps,
    Link as RouterLink,
    LinkProps as RouterLinkProps,
    useNavigate,
} from 'react-router-dom';

import snowplow from '../../snowplow-tracking';

interface LinkBaseProps {
    renderAs?: 'Link' | 'NavLink';
}

type CustomNavLinkProps = NavLinkProps & LinkBaseProps;
type CustomLinkProps = RouterLinkProps & LinkBaseProps;

type ComponentProps = CustomNavLinkProps | CustomLinkProps;

export const isNavLink = (props: ComponentProps): props is CustomNavLinkProps =>
    props.renderAs === 'NavLink';

export const Link: FC<ComponentProps> = ({ renderAs, ...rest }) => {
    const navigate = useNavigate();
    const { onClick, to } = rest;

    const handleClickEvent = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const anchorElement = e.target;

        if (anchorElement instanceof HTMLAnchorElement) {
            snowplow.trackLink(anchorElement);
        }

        onClick?.(e);

        if (to) {
            navigate(to);
        }
    };

    if (isNavLink({ renderAs, ...rest })) {
        return <NavLink data-routerlink {...rest} onClick={handleClickEvent} />;
    }

    return (
        <RouterLink
            data-routerlink
            {...(rest as RouterLinkProps)}
            onClick={handleClickEvent}
        />
    );
};
