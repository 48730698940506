import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';

import { HeaderResponseModel, HeaderService } from '../../services';

import { QueryKeys } from '../../domain/QueryKeys';

import { useAuth } from '../useAuth';

export const useHeaderService = (): UseQueryResult<HeaderResponseModel> => {
    const { makeRequest } = useAuth();
    return useQuery(
        [QueryKeys.getHeader],
        () => makeRequest<HeaderResponseModel>(HeaderService.getHeader),
        {
            staleTime: Infinity,
        }
    );
};

export const useSaveSubscriptionExpiryFeedback = () => {
    const { makeRequest } = useAuth();

    return useMutation((repliedTo: boolean) =>
        makeRequest(() =>
            HeaderService.saveSubscriptionExpiryFeedback(repliedTo)
        )
    );
};
