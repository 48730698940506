import { FC, useEffect } from 'react';

import { useAuth } from '../../hooks/useAuth';

export const LogoutPage: FC = () => {
    const { destroySession } = useAuth();
    useEffect(() => {
        destroySession();
    }, []);

    return null;
};
