import { FC, useEffect } from 'react';

import { OAuthError } from '@auth0/auth0-react';
import { Outlet } from 'react-router';

import { getLoginReturnToUrl, useAuth } from '../hooks/useAuth';
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';

import Spinner from '../components/Spinner/Spinner';

export const AuthenticatedRoute: FC = () => {
    const { setIsAuthenticated, hasUserCookie } = useIsAuthenticated();
    const { getAccessTokenSilently, loginWithRedirect } = useAuth();

    // Get valid access token if it's available
    // Redirect to login if no valid token exists
    useEffect(() => {
        let unmounted = false;

        (async () => {
            try {
                await getAccessTokenSilently();
                if (!unmounted) {
                    // set isAuthenticated to true if we have a valid token
                    setIsAuthenticated(true);
                }
            } catch (e) {
                const err = e as OAuthError;
                console.error(err.error);

                // redirect to auth0 login
                loginWithRedirect({
                    appState: {
                        returnTo: getLoginReturnToUrl(window.location.pathname),
                        queryString: window.location.search,
                    },
                });
            }
        })();

        return () => {
            unmounted = true;
        };
    }, []);

    if (!hasUserCookie) {
        return <Spinner />;
    }

    return <Outlet />;
};
