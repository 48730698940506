import { FC } from 'react';

type IconProps = {
    width?: string | number;
    height?: string | number;
    fill?: string;
};

const CirclePlusIcon: FC<IconProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 42 42"
            {...props}
        >
            <g transform="translate(9 9)">
                <rect fill="currentColor" width="24" height="24" opacity={0} />
                <path
                    fill="currentColor"
                    d="M8,17V10H1A1,1,0,1,1,1,8H8V1a1,1,0,1,1,2,0V8h7a1,1,0,0,1,0,2H10v7a1,1,0,0,1-2,0Z"
                    transform="translate(3 3)"
                />
            </g>
            <g stroke="currentColor" strokeWidth={2} fill="none">
                <circle cx="21" cy="21" r="21" stroke="none" />
                <circle cx="21" cy="21" r="20" fill="none" />
            </g>
        </svg>
    );
};

export default CirclePlusIcon;
