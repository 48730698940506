import { variations } from '../variations/variations';

export const notifications = {
    info: {
        lightest: '#E6F4FF',
        light: variations.azure[100],
        main: variations.azure[400],
        dark: variations.azure[600],
    },
    warning: {
        lightest: '#FFFBE6',
        light: '#FFF1CC',
        main: '#FFB444',
        dark: '#C17C15',
    },
    error: {
        lightest: '#FFF2FE',
        light: '#FFDADE',
        main: '#FF455B',
        dark: '#D21B31',
    },
    success: {
        lightest: '#E9FFFA',
        light: '#B8FFEE',
        main: '#00CA9A',
        dark: '#008767',
    },
};
