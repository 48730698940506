import { FC } from 'react';

type IconProps = {
    width?: string | number;
    height?: string | number;
    stroke?: string;
};

const MenuIcon: FC<IconProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="14"
            viewBox="0 0 26 14"
            stroke="currentColor"
            {...props}
        >
            <g transform="translate(1 1)">
                <line x2="24" strokeWidth="2px" />
                <line x2="24" strokeWidth="2px" transform="translate(0 6)" />
                <line x2="24" strokeWidth="2px" transform="translate(0 12)" />
            </g>
        </svg>
    );
};

export default MenuIcon;
