import { useContext } from 'react';

import {
    IsAuthenticatedContext,
    IsAuthenticatedContextProps,
} from '../context/IsAuthenticatedContext';

export const useIsAuthenticated = (): IsAuthenticatedContextProps => {
    const isAuthenticatedContext = useContext(IsAuthenticatedContext);

    return {
        ...isAuthenticatedContext,
    };
};
