import {
    FC,
    MutableRefObject,
    ReactNode,
    createContext,
    useMemo,
    useRef,
} from 'react';

export type HeaderContextInterface = {
    headerRef?: MutableRefObject<HTMLDivElement | null>;
    addStickyHeader(): void;
    removeStickyHeader(): void;
};

export const HeaderContext = createContext<HeaderContextInterface>({
    addStickyHeader: () => undefined,
    removeStickyHeader: () => undefined,
});

const mobileFixedHeaderClassName = 'mobile-sticky-header';

type HeaderContextProviderProps = {
    children?: ReactNode;
};

const HeaderContextProvider: FC<HeaderContextProviderProps> = ({
    children,
}) => {
    const headerRef = useRef<HTMLDivElement>(null);

    const addStickyHeader = () => {
        if (headerRef.current) {
            headerRef.current.classList.add(mobileFixedHeaderClassName);
        }
    };

    const removeStickyHeader = () => {
        if (headerRef.current) {
            headerRef.current.classList.remove(mobileFixedHeaderClassName);
        }
    };

    const contextValue = useMemo(
        () => ({
            headerRef,
            addStickyHeader,
            removeStickyHeader,
        }),
        [headerRef, addStickyHeader, removeStickyHeader]
    );

    return (
        <HeaderContext.Provider value={contextValue}>
            {children}
        </HeaderContext.Provider>
    );
};

export default HeaderContextProvider;
