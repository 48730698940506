import { ChangeEvent, Fragment } from 'react';

import './RadioGroup.scss';

export type RadioGroupProps<T> = {
    items: T[];
    groupId: string;
    onChange(entries: T[]): void;
};

type ExtendProps = {
    id?: string | number;
    title?: string | null;
    value?: boolean;
    description?: string | null;
};

export const RadioGroup = <T extends ExtendProps>({
    items,
    groupId,
    onChange,
}: RadioGroupProps<T>) => {
    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const updated = [...items].map((item) => {
            const itemId = item.id ? item.id.toString() : '';
            return {
                ...item,
                value: itemId === evt.target.value && evt.target.checked,
            };
        });
        onChange(updated);
    };

    return (
        <div className="chw-radio-group">
            <fieldset id={groupId}>
                {items.map((item) => (
                    <Fragment key={item.id}>
                        <label
                            data-testid="chw-radio-item"
                            className={`chw-radio-item ${
                                item.value ? 'checked' : ''
                            }`}
                        >
                            <span className="chw-radio-item__input-wrapper">
                                <input
                                    className="chw-radio-item__input"
                                    type="radio"
                                    value={item.id}
                                    name={groupId}
                                    checked={item.value}
                                    onChange={handleChange}
                                    data-testid="chw-radio-input"
                                />
                            </span>
                            {item.title && (
                                <span className="chw-radio-item__text">
                                    {item.title}
                                </span>
                            )}
                        </label>
                        {item.description && (
                            <span className="chw-radio-item__description">
                                {item.description}
                            </span>
                        )}
                    </Fragment>
                ))}
            </fieldset>
        </div>
    );
};
