import { FC } from 'react';

type IconProps = {
    width?: string | number;
    height?: string | number;
    fill?: string;
};

const CloseIcon: FC<IconProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="close-icon"
            {...props}
        >
            <rect width="24" height="24" opacity={0} />
            <path
                d="M42.021-3.565l-4.95-4.95-4.95,4.95a1,1,0,0,1-1.414,0,1,1,0,0,1,0-1.414l4.95-4.95-4.95-4.95a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l4.95,4.95,4.95-4.95a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-4.95,4.95,4.95,4.95a1,1,0,0,1,0,1.414,1,1,0,0,1-.707.293A1,1,0,0,1,42.021-3.565Z"
                transform="translate(-25.071 21.929)"
            />
        </svg>
    );
};

export default CloseIcon;
