import { CampaignItem, HeaderResponseModel } from '../services';

export const getDataLabLink = (
    headerData?: Partial<HeaderResponseModel>
): string => {
    if (!headerData) {
        return '';
    }
    const headerMenu = headerData.menu?.menuItems || [];

    const submenusFlattened = headerMenu
        .flatMap(({ subMenu }) => [...(subMenu || [])])
        .flatMap(({ items }) => [...(items || [])]);

    const dataLab = submenusFlattened.find(({ usage }) => usage === 'datalab');
    return dataLab?.link || '';
};

export const getCampaignByName = (
    campaignName: string,
    campaignsData?: CampaignItem[]
): CampaignItem | undefined => {
    const campaigns = campaignsData || [];
    return campaigns.find(({ name }) => name === campaignName);
};
