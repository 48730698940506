import { FC, ReactNode, createContext, useMemo, useState } from 'react';

export type Language = 'en' | 'es' | 'zh';

export type LanguageContextProps = {
    language: Language;
    setLanguage: (language: Language) => void;
};

export const LanguageContext = createContext<LanguageContextProps | undefined>(
    undefined
);

interface LanguageProviderProps {
    children: ReactNode;
}

export const LanguageProvider: FC<LanguageProviderProps> = ({ children }) => {
    const [language, setLanguage] = useState<Language>('en');

    const contextValue = useMemo(
        () => ({
            language,
            setLanguage,
        }),
        [language]
    );

    return (
        <LanguageContext.Provider value={contextValue}>
            {children}
        </LanguageContext.Provider>
    );
};

const LanguageContextProvider = LanguageProvider;
export default LanguageContextProvider;
