import { FC } from 'react';

type IconProps = {
    width?: string | number;
    height?: string | number;
    fill?: string;
};

const WarningIcon: FC<IconProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            {...props}
        >
            <defs>
                <clipPath id="warining-icon-a">
                    <path
                        clipRule="evenodd"
                        fill="currentColor"
                        d="M428-951a7.086,7.086,0,0,0-7,7,7.086,7.086,0,0,0,7,7,7.02,7.02,0,0,0,7-7A6.957,6.957,0,0,0,428-951Zm-.5,3h1v5h-1Zm.5,8.2a.86.86,0,0,1-.8-.8.789.789,0,0,1,.8-.8.86.86,0,0,1,.8.8A.86.86,0,0,1,428-939.8Z"
                        transform="translate(-421 951)"
                    />
                </clipPath>
                <clipPath id="warining-icon-b">
                    <path
                        fill="currentColor"
                        d="M192-905H480v-83H192Z"
                        transform="translate(-192 988)"
                    />
                </clipPath>
            </defs>
            <g style={{ clipPath: 'url(#warining-icon-a)' }}>
                <g
                    style={{ clipPath: 'url(#warining-icon-b)' }}
                    transform="translate(-229 -37)"
                >
                    <path
                        fill="currentColor"
                        d="M416-956h24v24H416Z"
                        transform="translate(-192 988)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default WarningIcon;
