import {
    UseMutationResult,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';

import { CampaignItem, CampaignService } from '../../services';

import { QueryKeys } from '../../domain/QueryKeys';

import { useAuth } from '../useAuth';

type CampaignPayload = {
    campaignId: number;
    notes?: string;
};

type UseCampaignResult = {
    setCampaign: UseMutationResult<unknown, unknown, CampaignPayload>;
    setNotification: UseMutationResult<unknown, unknown, CampaignPayload>;
};

export const useGetCampaigns = () => {
    const { makeRequest } = useAuth();
    return useQuery(
        [QueryKeys.getCampaigns],
        () => makeRequest<CampaignItem[]>(CampaignService.getCampaigns),
        {
            staleTime: Infinity, // don't refetch the campaigns data
        }
    );
};

export const useUpdateCampaign = (): UseCampaignResult => {
    const { makeRequest } = useAuth();
    const queryClient = useQueryClient();

    const setCampaign = useMutation(
        ({ campaignId, notes }: CampaignPayload) =>
            makeRequest(() => CampaignService.setCampaign(campaignId, notes)),
        {
            onSuccess: () => {
                // mark query as stale but don't refetch!
                // n.b. it still gets refetched on DatalabPromo (bug?) !!
                queryClient.invalidateQueries([QueryKeys.getCampaigns], {
                    refetchType: 'none', // don't refetch until needed
                });
            },
        }
    );

    const setNotification = useMutation(
        ({ campaignId, notes }: CampaignPayload) =>
            makeRequest(() =>
                CampaignService.setNotification(campaignId, notes)
            )
    );

    return {
        setCampaign,
        setNotification,
    };
};
