import { FC } from 'react';

type IconProps = {
    width?: string | number;
    height?: string | number;
    fill?: string;
};

const UserIcon: FC<IconProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            {...props}
        >
            <path d="M19,7a7,7,0,1,1-7-7,7,7,0,0,1,7,7Zm-1.6,7.18a8.93,8.93,0,0,1-10.809,0C2.521,15.972,0,21.555,0,24H24C24,21.577,21.4,15.994,17.4,14.181Z" />
        </svg>
    );
};

export default UserIcon;
