import { alpha } from '@mui/system/colorManipulator';

import type { PaletteOptions } from '@mui/material/styles/createPalette';

import { core } from './colours/core/core';
import { linearGradients } from './colours/linear-gradients/linear-gradients';
import { monochromes } from './colours/monochromes/monochromes';
import { notifications } from './colours/notifications/notifications';
import { variations } from './colours/variations/variations';

type createPaletteProps = {
    mode?: 'light' | 'dark';
};

export const createPalette = ({
    mode = 'light',
}: createPaletteProps = {}): PaletteOptions => {
    const { aubergine, azure, blueBlack } = variations;
    const { greys } = monochromes;
    const { error, info, success, warning } = notifications;
    const {
        azure: azureCore,
        blueBlack: blueBlackCore,
        deepAubergine,
        white,
    } = core;
    const { bgDarkBlueGradient, bgLightGreyGradient } = linearGradients;

    return {
        primary: azure,
        secondary: {
            main: aubergine[400],
        },
        mode,
        core: {
            azure: azureCore,
            deepAubergine: deepAubergine,
            blueBlack: blueBlackCore,
            white,
        },
        variations,
        monochromes,
        linearGradients: {
            bgDarkBlueGradient: bgDarkBlueGradient,
            bgLightGreyGradient: bgLightGreyGradient,
        },
        error,
        info,
        success,
        warning,
        action: {
            // TODO - states need to be updated when we know them
            active: greys[500],
            disabled: alpha(greys[900], 0.38),
            disabledBackground: alpha(greys[900], 0.12),
            focus: alpha(greys[900], 0.16),
            hover: alpha(greys[900], 0.04),
            selected: alpha(greys[900], 0.12),
        },
        background: {
            default: greys[50],
            paper: greys[50],
        },
        text: {
            primary: blueBlack[400],
            secondary: white,
            disabled: alpha(blueBlack[400], 0.38),
        },
        divider: greys[300],
    };
};
