import { FC, ReactNode, createContext, useMemo, useState } from 'react';

export type FeedbackFormContextState = {
    toggleFeedbackForm: (visible: boolean) => void;
    feedbackFormVisible: boolean;
};

export const FeedbackFormContext = createContext<FeedbackFormContextState>({
    toggleFeedbackForm: () => undefined,
    feedbackFormVisible: false,
});

type FeedbackFormContextProviderProps = { children: ReactNode };

const FeedbackFormContextProvider: FC<FeedbackFormContextProviderProps> = ({
    children,
}) => {
    const [feedbackFormVisible, setFeedbackFormVisible] = useState(false);

    const contextValue = useMemo(
        () => ({
            toggleFeedbackForm: setFeedbackFormVisible,
            feedbackFormVisible,
        }),
        [feedbackFormVisible, setFeedbackFormVisible]
    );

    return (
        <FeedbackFormContext.Provider value={contextValue}>
            {children}
        </FeedbackFormContext.Provider>
    );
};

export default FeedbackFormContextProvider;
