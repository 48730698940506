import classNames from 'classnames';
import { IoGridOutline } from 'react-icons/io5';
import { useLocation } from 'react-router';

import { Pages } from '../../../../../domain/Pages';

import { Link } from '../../../../Link/Link';
import '../HeaderSubMenuItem/HeaderSubMenuItem.scss';

const myPrices = {
    title: 'My Prices',
    link: `../${Pages.mycru}?tab=my-prices`,
    icon: <IoGridOutline />,
};

export const MyPrices: React.FC = () => {
    const { pathname, search } = useLocation();
    const isActive = myPrices.link.includes(`${pathname}${search}`);
    const myPricesLinkClasses = classNames('header-sub-menu-item--with-icon', {
        active: isActive,
    });
    return (
        <li className="header-sub-menu-item">
            <Link
                to={myPrices.link}
                data-snowplow-group="site_navigation"
                className={myPricesLinkClasses}
            >
                {myPrices.title}
                {myPrices.icon}
            </Link>
        </li>
    );
};
