import { FC, ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import './PageBanner.scss';

export type BannerTheme = 'light' | 'dark';

export interface PageBannerProps {
    title: ReactNode;
    theme?: BannerTheme;
    className?: string;
    background?: string;
    preHeadlineContent?: ReactNode;
    postHeadlineContent?: ReactNode;
    desktopOnly?: boolean;
    children?: ReactNode;
}

export const testId = 'chw-pagebanner';

export const PageBanner: FC<PageBannerProps> = ({
    children,
    className,
    theme,
    title,
    background,
    preHeadlineContent,
    postHeadlineContent,
    desktopOnly,
}) => {
    const classes = classNames('pagebanner', theme, className, {
        'pagebanner--desktop-only': desktopOnly,
    });

    const backgroundStyle = useMemo(() => {
        if (!background) {
            return {};
        }
        return {
            backgroundImage: `url(${background})`,
        };
    }, [background]);

    return (
        <div className={classes} data-testid={testId}>
            <div
                style={backgroundStyle}
                data-testid={`${testId}_background`}
                className="pagebanner__background"
            >
                <div className="pagebanner__container">
                    {preHeadlineContent}
                    <div className="pagebanner__content">
                        {title && <h1>{title}</h1>}
                        <div className="pagebanner__content-post">
                            {postHeadlineContent}
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};
