import { FC, useContext, useMemo } from 'react';

import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { Pages } from '../../../../../domain/Pages';

import { MobileMenuContext } from '../../../../../context/MobileMenuContext';

import { useAlerts } from '../../../../../hooks/data/useAlerts';
import { useDeviceContext } from '../../../../../hooks/useDeviceContext';
import { useHeader } from '../../../../../hooks/useHeader';

import NotificationIcon from '../../../../Icons/NotificationIcon';
import { Link } from '../../../../Link/Link';
import { Pill } from '../../../../Pill/Pill';
import './NotificationsMenu.scss';

export const testId = 'header-notifications';

export const NotificationsMenu: FC = () => {
    const { data } = useAlerts();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { smallDevice } = useDeviceContext();
    const { activatedMobileMenu, onToggleMobileMenu } =
        useContext(MobileMenuContext);
    const { isActiveNavLink } = useHeader();

    const onMenuButtonClick = () => {
        if (smallDevice) {
            if (activatedMobileMenu !== 'notification') {
                onToggleMobileMenu('notification');
            } else {
                onToggleMobileMenu(undefined);
            }
        } else {
            navigate(Pages.notifications);
        }
    };

    const menuClassName = classNames(
        'header-sub-menu notifications-menu-container',
        {
            'is-mobile': smallDevice,
            'is-open': activatedMobileMenu === 'notification',
        }
    );

    const buttonClassName = classNames(
        'header-nav-item__button notifications-menu__button',
        {
            current: isActiveNavLink(Pages.notifications),
        }
    );

    const showBadge = useMemo(() => {
        if (
            !data?.unreadCount ||
            pathname === Pages.notifications ||
            data.unreadCount <= 0
        ) {
            return false;
        }
        return true;
    }, [pathname, data?.unreadCount]);

    if (!data) {
        return null;
    }

    return (
        <div
            id="chw-header-notifications"
            className="header-nav-item notifications-menu"
            data-testid={testId}
        >
            <button
                className={buttonClassName}
                data-testid="notifications-trigger"
                onClick={onMenuButtonClick}
            >
                <span className="notifications-menu__button-inner">
                    <span className="sr-only">Your notifications</span>
                    <NotificationIcon
                        height={smallDevice ? 22 : 20}
                        className="notification-icon"
                    />
                    {showBadge && (
                        <Pill className="notifications-menu__badge">
                            {data.unreadCount}
                        </Pill>
                    )}
                </span>
            </button>
            <div className={menuClassName} data-testid="notifications-panel">
                <div className="header-sub-menu-category">
                    <h3
                        className="notifications-menu__list-title"
                        data-testid="notifications-headline"
                    >
                        Notifications
                    </h3>
                    <ul
                        className="header-sub-menu-category-items notifications-menu__list"
                        data-testid="notifications-list"
                    >
                        {data.foundNotifications?.map(
                            ({ articleId, urlName, title }) => (
                                <li key={articleId}>
                                    <Link
                                        to={`${Pages.notifications}/article/${articleId}/${urlName}`}
                                        data-snowplow-group="site_navigation"
                                    >
                                        {title}
                                    </Link>
                                </li>
                            )
                        )}
                    </ul>
                    <div className="notifications-menu__cta">
                        <Link
                            to={Pages.notifications}
                            data-snowplow-group="site_navigation"
                        >
                            All Notifications
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
