import { getConfigValue } from '../util/config';

type UseFeatureFlags = {
    snowplowEnabled: boolean;
    chatAiEnabled: boolean;
    articleTranslationEnabled: boolean;
    articleTranslationSummaryEnabled: boolean;
    articleTranslationTranslateEnabled: boolean;
};

const snowplowEnabled =
    getConfigValue('REACT_APP_FF_ENABLE_SNOWPLOW') === 'true';
const chatAiEnabled = getConfigValue('REACT_APP_FF_SHOW_CHAT_AI') === 'true';
const articleTranslationEnabled =
    getConfigValue('REACT_APP_FF_SHOW_ARTICLE_TRANSLATION') === 'true';
const articleTranslationSummaryEnabled =
    getConfigValue('REACT_APP_FF_SHOW_ARTICLE_TRANSLATION_SUMMARY') === 'true';
const articleTranslationTranslateEnabled =
    getConfigValue('REACT_APP_FF_SHOW_ARTICLE_TRANSLATION_TRANSLATE') ===
    'true';

export const useFeatureFlags = (): UseFeatureFlags => ({
    snowplowEnabled,
    chatAiEnabled,
    articleTranslationEnabled,
    articleTranslationSummaryEnabled,
    articleTranslationTranslateEnabled,
});
