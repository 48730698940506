import { useEffect } from 'react';

import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import { ContentFilter } from '../services';

import { getConfigValue } from '../util/config';

import { useAuth } from './useAuth';

export enum EventName {
    SearchFilter = 'search_filter_event',
    AnalysisFilter = 'analysis_filter_event',
    NotificationsFilter = 'notifications_filter_event',
    SiteSearch = 'site_search',
    BookmarkAnalysis = 'analysis_list_bookmark',
    BookmarkSearch = 'search_results_bookmark',
    BookmarkArticle = 'article_bookmark',
    AnalysisPaging = 'analysis_list_paging',
    SearchResultPaging = 'search_results_paging',
    NotificationsPaging = 'notifications_paging',
    PricesDownload = 'prices_download_event',
    SavedSearch = 'save_search_event',
    SavedFilter = 'save_filter_event',
    PriceBookmark = 'price_bookmark',
    InlineDocumentViewerPaging = 'inline_document_viewer_paging',
}

type SavedSearchEventPayload = {
    event: EventName.SavedSearch | EventName.SavedFilter;
    saved_search_label: string;
};

type PricesDownloadEventPayload = {
    event: EventName.PricesDownload;
    link_url: string;
};

type SearchEventPayload = {
    event: EventName.SiteSearch;
    search_term: string;
};

type PagingEventPayload = {
    event:
        | EventName.AnalysisPaging
        | EventName.NotificationsPaging
        | EventName.SearchResultPaging;
    page_number: number;
};

type BookmarkEventPayload = {
    event:
        | EventName.BookmarkAnalysis
        | EventName.BookmarkArticle
        | EventName.BookmarkSearch;
    bookmark_action: 'selected' | 'unselected';
    link_url: string;
};

type PriceBookmarkEventPayload = {
    event: EventName.PriceBookmark;
    price_id: number;
    price_frequency_id: number;
    price_bundle_id: number;
    bookmark_action: 'selected' | 'unselected';
    link_url: string;
};

type InlineDocumentViewerPagingEventPayload = {
    event: EventName.InlineDocumentViewerPaging;
    document_title: string;
    service_type: string;
    publication_date: string;
    url: string;
    page_number: number;
    document_id: number;
    article_id: number;
    view_mode: string;
    upload_type: string;
    document_type: string;
};

type FilterEventPayload = {
    event:
        | EventName.AnalysisFilter
        | EventName.NotificationsFilter
        | EventName.SearchFilter;
    filters: {
        filterOpts: ContentFilter[];
        selected: ContentFilter[];
    };
};

export type TrackingEventPayload =
    | PricesDownloadEventPayload
    | SavedSearchEventPayload
    | SearchEventPayload
    | PagingEventPayload
    | BookmarkEventPayload
    | PriceBookmarkEventPayload
    | InlineDocumentViewerPagingEventPayload;

type UseEventAnalyticsResult = {
    trackEvent: (payload: TrackingEventPayload) => void;
    trackFilterEvent: (payload: FilterEventPayload) => void;
};

export const useEventAnalytics = (): UseEventAnalyticsResult => {
    const { userId, isAuthenticated } = useAuth();

    const initialiseAnalytics = () => {
        const gtmId = getConfigValue('REACT_APP_TAG_MANAGER_ID');
        const gaMeasurementId = getConfigValue('REACT_APP_GA_MEASUREMENT_ID');
        const gaOptions = {
            gaOptions: {
                userId,
            },
        };

        TagManager.initialize({
            gtmId,
        });
        ReactGA.initialize(gaMeasurementId, gaOptions);
        ReactGA.set({
            user_properties: {
                cru_crm_id: userId,
            },
        });
    };

    useEffect(() => {
        if (userId && isAuthenticated && !ReactGA.isInitialized) {
            initialiseAnalytics();
        }
    }, [userId, isAuthenticated, ReactGA.isInitialized]);

    const trackEvent = ({ event, ...reset }: TrackingEventPayload) => {
        const newEvent = {
            event,
            userId,
            cru_crm_id: userId,
            ...reset,
        };
        window.dataLayer.push(newEvent);
    };

    const trackFilterEvent = ({ event, filters }: FilterEventPayload) => {
        const { selected, filterOpts } = filters;

        const eventData = filterOpts.map((filterOpt) => {
            const keyName = `${
                filterOpt.title || ''
            }_${event}`.toLocaleLowerCase();
            const hasSelected = selected.some((selectedOpt) => {
                const entries = selectedOpt.entries || [];
                return selectedOpt.id === filterOpt.id && entries.length > 0;
            });
            return [[keyName], hasSelected ? 'selected' : 'not_selected'];
        });

        const newEvent: Record<string, string> = {
            event,
            ...Object.fromEntries(eventData),
        };

        window.dataLayer.push(newEvent);
    };

    return {
        trackEvent,
        trackFilterEvent,
    };
};
