import { FC, Fragment, useState } from 'react';

import { getConfigValue } from '../../util/config';

import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

const tourUrl = getConfigValue('REACT_APP_TOUR_BOOKING_URL');

export const contactPhoneNumbers = [
    {
        label: 'London',
        tel: '+44 20 7903 2146',
    },
    {
        label: 'USA',
        tel: '+1 724 720 1013',
    },
    {
        label: 'Santiago',
        tel: '+562 2760 3470',
    },
    {
        label: 'Beijing',
        tel: '+86 10 5082 8333',
    },
];

export const content = {
    btnText: 'Contact Us',
    title: 'Need Assistance?',
    intro: "CRU's dedicated Global Customer Services Team is here to help you make the most of our delivery platform and to ensure you know how to best manage your account so it works for you. Of course, we are also here to help with any general questions you may have.",
    emailHref: 'mailto:customer.services@crugroup.com',
    emailText: 'Email Us',
    phoneNumbers: contactPhoneNumbers,
    callText: 'Book a meeting',
};

const containerClassName = 'contact-us-modal';

export type ContactNumbersProps = {
    contacts: { label: string; tel: string }[];
};

export const ContactNumbers: FC<ContactNumbersProps> = (contactNumbers) => {
    return (
        <dl className="grid grid-cols-[auto,1fr] gap-x-4 gap-y-2">
            {contactNumbers.contacts.map(({ label, tel }) => (
                <Fragment key={label}>
                    <dt className="font-bold">{label}</dt>
                    <dd>
                        <a href={`tel:${tel}`}>{tel}</a>
                    </dd>
                </Fragment>
            ))}
        </dl>
    );
};

export const ContactUsModalContent: FC = () => {
    return (
        <div className={containerClassName}>
            <p>{content.intro}</p>

            <p className="flex gap-5">
                <a href={content.emailHref}>{content.emailText}</a>
                <a
                    className="gtm-schedule-call"
                    href={tourUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    {content.callText}
                </a>
            </p>

            <ContactNumbers contacts={contactPhoneNumbers} />
        </div>
    );
};

export const ContactUs: FC = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button
                customClass="contact-us-btn"
                onClick={() => setShowModal(true)}
                variant="outline"
                theme="light"
            >
                {content.btnText}
            </Button>

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                title={content.title}
                content={<ContactUsModalContent />}
            />
        </>
    );
};
