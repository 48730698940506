import { useContext } from 'react';

import {
    ImpersonationContext,
    ImpersonationContextInterface,
} from '../context/ImpersonationContext';

export const useImpersonateUser = (): ImpersonationContextInterface => {
    const impersonationContext = useContext(ImpersonationContext);

    return {
        ...impersonationContext,
    };
};
