import { ContentFilter, ContentFilterEntry } from '../services';

import { CheckBoxSelectedState } from '../components/Checkbox/CheckBox';

export const anySet: (filters: ContentFilter[]) => boolean = (filters) =>
    filters.some((f) => f.entries && f.entries.length !== 0);

export const findBySearchTerm = (
    searchTerm: string,
    filterEntries: ContentFilterEntry[]
): string[] =>
    filterEntries.reduce<string[]>((accumulator, entry: ContentFilterEntry) => {
        const title = (entry.title || '').toLocaleLowerCase();
        const titleMatch =
            searchTerm === ''
                ? true
                : title.includes(searchTerm.toLocaleLowerCase());
        let childEntries: string[] = [];

        // search the child entries
        if (entry.entries && entry.entries.length > 0) {
            const term = titleMatch ? '' : searchTerm;
            childEntries = findBySearchTerm(term, entry.entries);
        }

        if (titleMatch || childEntries.length > 0) {
            return accumulator.concat([entry.id || '', ...childEntries]);
        }

        return accumulator;
    }, []);

export type GetChosenFiltersFlattened = {
    filters: ContentFilter[];
    count: number;
};

export const getChosenFiltersFlattened = (
    filters: ContentFilter[]
): GetChosenFiltersFlattened => {
    let count = 0;

    const getCheckedFilterEntries = (
        filterEntries: ContentFilterEntry[]
    ): ContentFilterEntry[] =>
        filterEntries.reduce<ContentFilterEntry[]>(
            (accumulator, entry: ContentFilterEntry) => {
                const isChecked = entry.value === 'checked';
                if (isChecked) {
                    count++;
                }
                if (entry.entries && entry.entries.length > 0) {
                    const checkedChildEntries = getCheckedFilterEntries(
                        entry.entries
                    );
                    const result = isChecked
                        ? [{ ...entry }, ...checkedChildEntries]
                        : [...checkedChildEntries];
                    return accumulator.concat(result);
                } else if (isChecked) {
                    return accumulator.concat([{ ...entry }]);
                }

                return accumulator;
            },
            []
        );

    const updateFilters = filters.map((filter) => {
        const filterEntries = filter.entries || [];
        let entries = filterEntries;
        if (filter.type === 'date') {
            count = count + entries.length;
        } else {
            entries = getCheckedFilterEntries(filterEntries);
        }
        return {
            ...filter,
            entries,
        };
    });
    return {
        filters: updateFilters,
        count,
    };
};

/**
 * Get the selected filters entries from a specified parent filter e.g Commodity, Topic
 * @returns ContentFilterEntry[]
 */
export const selectedFilterEntries = (
    filterEntries: ContentFilterEntry[]
): ContentFilterEntry[] =>
    filterEntries.reduce<ContentFilterEntry[]>(
        (accumulator, entry: ContentFilterEntry) => {
            const entries = entry.entries || [];
            const isChecked = entry.value === 'checked';

            if (!isChecked && entries.length > 0) {
                const childEntries = selectedFilterEntries(entries);

                if (childEntries.length > 0) {
                    return accumulator.concat([
                        { ...entry, entries: childEntries },
                    ]);
                }
            } else if (isChecked) {
                return accumulator.concat([{ ...entry }]);
            }

            return accumulator;
        },
        []
    );

/**
 * Get filters which are selected or have selected descendants
 * @returns ContentFilter[]
 */
export const getSelectedFilters = (
    filters: ContentFilter[]
): ContentFilter[] => {
    const selectedFilters: ContentFilter[] = [];

    for (const filterGroup of filters) {
        const entries = filterGroup.entries || [];
        const selectedEntries =
            filterGroup.type === 'select'
                ? selectedFilterEntries(entries)
                : entries.filter((entry) => entry.value);

        if (selectedEntries.length > 0) {
            selectedFilters.push({
                ...filterGroup,
                entries: selectedEntries,
            });
        }
    }

    return selectedFilters;
};

/**
 * Reset filter entries to be unselected or selected from a specified parent filter e.g Commodity, Topic
 * @returns ContentFilterEntry[]
 */
export const resetFilterEntries = (
    filterEntries: ContentFilterEntry[],
    newValue: CheckBoxSelectedState
): ContentFilterEntry[] =>
    filterEntries.map((entry: ContentFilterEntry) => {
        const entries = entry.entries || [];

        if (entries.length > 0) {
            const updatedChildEntries = resetFilterEntries(entries, newValue);
            return {
                ...entry,
                value: newValue,
                entries: updatedChildEntries,
            };
        }

        return {
            ...entry,
            value: newValue,
        };
    });

/**
 * Resets all filters and it's descendant filters to be unselected
 * @returns ContentFilter[]
 */
export const resetFilters = (
    filters: ContentFilter[],
    newValue: CheckBoxSelectedState
): ContentFilter[] =>
    filters.map((filterGroup) => {
        const entries = filterGroup.entries || [];

        const updatedEntries = resetFilterEntries(entries, newValue);

        return {
            ...filterGroup,
            entries: updatedEntries,
        };
    });

/**
 * Reset filter entry to be unselected
 * @returns ContentFilter[]
 */
export const resetFilterEntryValue = (
    entries: ContentFilterEntry[],
    id: string
): ContentFilterEntry[] =>
    entries.map((filterEntry) => {
        const match = filterEntry.id === id;
        const entries = filterEntry.entries || [];

        if (match) {
            return {
                ...filterEntry,
                value: '',
            };
        } else if (entries.length > 0) {
            const updatedEntries = resetFilterEntryValue(entries, id);

            return {
                ...filterEntry,
                entries: updatedEntries,
            };
        }

        return filterEntry;
    });
