import { FC } from 'react';

import { useDeviceContext } from '../../hooks/useDeviceContext';

import { Button } from '../Button/Button';

export type FeedbackButtonProps = {
    formVisible: boolean;
    showForm: () => void;
};

export const FeedbackButton: FC<FeedbackButtonProps> = ({
    formVisible,
    showForm,
}) => {
    const { smallDevice } = useDeviceContext();

    if (smallDevice) {
        return (
            <Button
                customClass="feedback-btn"
                variant="outline"
                theme="light"
                onClick={showForm}
                testId="feedback-mobile-button"
            >
                Feedback
            </Button>
        );
    }

    if (formVisible) {
        return null;
    }

    return (
        <button
            className="feedback-form__trigger"
            onClick={showForm}
            data-testid="feedback-desktop-button"
        >
            <span>Feedback</span>
        </button>
    );
};
