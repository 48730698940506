import { FC, ReactNode, createContext, useMemo, useState } from 'react';

import { useImpersonateUserService } from '../hooks/data/useImpersonateUserService';

import { ImpersonationBar } from '../components/ImpersonationBar/ImpersonationBar';
import { ImpersonationModal } from '../components/ImpersonationModal/ImpersonationModal';

export type ImpersonationContextInterface = {
    isImpersonating: boolean;
    userName: string;
    modalOpen: boolean;
    toggleModal: (showModal: boolean) => void;
    startImpersonation: (userEmail: string) => void;
    stopImpersonation: () => void;
    error: string;
};

export const ImpersonationContext =
    createContext<ImpersonationContextInterface>({
        isImpersonating: false,
        userName: '',
        modalOpen: false,
        toggleModal: () => undefined,
        startImpersonation: () => undefined,
        stopImpersonation: () => undefined,
        error: '',
    });

type ImpersonationContextProviderProps = {
    children?: ReactNode;
};

const ImpersonationContextProvider: FC<ImpersonationContextProviderProps> = ({
    children,
}) => {
    const [error, setError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [userName, setUserName] = useState('');
    const [customHeaderName, setCustomHeaderName] = useState('');

    const { impersonateUser, resetImpersonateUser } =
        useImpersonateUserService();

    const toggleModal = (showModal: boolean) => {
        setModalOpen(showModal);
        setError('');
    };

    const startImpersonation = (userEmail: string) => {
        setError('');
        impersonateUser.mutate(userEmail, {
            onSuccess: (apiResponse) => {
                const key = apiResponse.headerName || '';
                setUserName(userEmail);
                setCustomHeaderName(key);
                toggleModal(false);
            },
            onError: (error) => {
                if (error.status === 404) {
                    return setError('User not found');
                }
                if (error.status === 421) {
                    return setError('You can not impersonate your own user');
                }
                return setError('Something went wrong');
            },
        });
    };

    const stopImpersonation = () => {
        setUserName('');
        setCustomHeaderName('');
        resetImpersonateUser(customHeaderName);
    };

    const isImpersonating = useMemo(() => userName !== '', [userName]);

    const contextValue = useMemo(
        () => ({
            isImpersonating,
            userName,
            modalOpen,
            toggleModal,
            startImpersonation,
            stopImpersonation,
            error,
        }),
        [
            isImpersonating,
            userName,
            modalOpen,
            toggleModal,
            startImpersonation,
            stopImpersonation,
            error,
        ]
    );

    return (
        <ImpersonationContext.Provider value={contextValue}>
            {children}
            <ImpersonationBar />
            <ImpersonationModal />
        </ImpersonationContext.Provider>
    );
};

export default ImpersonationContextProvider;
