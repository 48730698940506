import { FC } from 'react';

type IconProps = {
    width?: string | number;
    height?: string | number;
    fill?: string;
    className?: string;
};

const ChevronDownIcon: FC<IconProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            {...props}
        >
            <rect opacity={0} width="24" height="24" />
            <path
                d="M.293,13.021a1,1,0,0,1,0-1.414l4.95-4.95L.293,1.707A1,1,0,0,1,1.707.293L7.364,5.95a1,1,0,0,1,0,1.415L1.707,13.021a1,1,0,0,1-1.415,0Z"
                transform="translate(18.829 8.171) rotate(90)"
            />
        </svg>
    );
};

export default ChevronDownIcon;
