import { alpha } from '@mui/material';

import { ColorRange } from '@mui/material/styles/createPalette';

const greys: ColorRange = {
    50: '#FBFDFF',
    100: '#F2F4F8',
    200: '#DDE1E6',
    300: '#C1C7CD',
    400: '#878D96',
    500: '#697077',
    600: '#343A3F',
    700: '#121619',
    800: '#000',
    900: '#000',
};

export const monochromes = {
    greys,
    transparent: {
        lightest: alpha(greys[50], 0.4),
        light: alpha(greys[700], 0.5),
        dark: alpha(greys[700], 0.9),
    },
};
