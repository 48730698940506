import { FC, MouseEvent, useMemo } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { isExternal } from '../../../../../util/isExternalUrl';

import { Link } from '../../../../Link/Link';
import './HeaderSubMenuItem.scss';

export type HeaderSubMenuItemProps = {
    title: string;
    link?: string | null;
    categoryLink?: string | null;
    onClick?: () => void;
    id?: string;
    testId?: string;
    customClass?: string;
    customLinkClass?: string;
};

export const HeaderSubMenuItem: FC<HeaderSubMenuItemProps> = ({
    title,
    link,
    categoryLink,
    onClick,
    id,
    customClass,
    customLinkClass,
    testId,
}) => {
    const { pathname, search } = useLocation();

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.currentTarget.blur();

        if (typeof onClick === 'function') {
            e.preventDefault();
            onClick();
        }
    };

    const isExternalLink = isExternal(link || '');
    const to = useMemo(
        () => (categoryLink ? `${categoryLink}${link}` : link),
        [categoryLink, link]
    );

    const isActive = useMemo(() => {
        if (!to) {
            return false;
        }

        const [linkPathName, linkQueryString] = to.split('?');

        if (linkQueryString) {
            return (
                linkPathName === pathname && search.includes(linkQueryString)
            );
        }
        return linkPathName === pathname;
    }, [to, pathname, search]);

    const classes = classNames('header-sub-menu-item', customClass);
    const navLinkClasses = classNames(
        {
            active: isActive,
        },
        customLinkClass
    );

    return (
        <li className={classes}>
            {isExternalLink ? (
                <a
                    href={link || ''}
                    target="_blank"
                    rel="noreferrer"
                    id={id}
                    data-testid={testId}
                    className={navLinkClasses}
                    data-snowplow-group="site_navigation"
                >
                    {title}
                </a>
            ) : (
                <Link
                    data-testid={testId}
                    to={to || ''}
                    onClick={handleClick}
                    id={id}
                    className={navLinkClasses}
                    data-snowplow-group="site_navigation"
                >
                    {title}
                </Link>
            )}
        </li>
    );
};
