import { FC } from 'react';

import { SubMenuCategory, SubMenuItem } from '../../../../../services';

import {
    HeaderSubMenuItem,
    HeaderSubMenuItemProps,
} from '../HeaderSubMenuItem/HeaderSubMenuItem';
import './HeaderSubMenuCategory.scss';

export type SubMenuItemExtend = SubMenuItem & {
    component?: FC<HeaderSubMenuItemProps>;
};

export type SubMenuCategoryProps = Omit<SubMenuCategory, 'items'> & {
    items?: SubMenuItemExtend[];
};

export const HeaderSubMenuCategory: FC<SubMenuCategoryProps> = ({
    items,
    title,
    link: categoryLink,
}) => {
    const menuItems = items || [];
    if (menuItems.length === 0) {
        return null;
    }

    return (
        <div className="header-sub-menu-category">
            {title && (
                <h4 className="header-sub-menu-category__header">{title}</h4>
            )}
            <ul className="header-sub-menu-category-items">
                {menuItems.map(({ title, link, usage, component }, index) => {
                    const Component = component || HeaderSubMenuItem;
                    return (
                        <Component
                            title={title || ''}
                            link={link}
                            categoryLink={categoryLink}
                            key={index}
                            id={usage || undefined}
                        />
                    );
                })}
            </ul>
        </div>
    );
};
