/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImpersonatePostModel } from '../models/ImpersonatePostModel';
import type { ImpersonationInfoViewModel } from '../models/ImpersonationInfoViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImpersonationService {

    /**
     * @param requestBody
     * @returns ImpersonationInfoViewModel Success
     * @throws ApiError
     */
    public static postApiImpersonate(
        requestBody?: ImpersonatePostModel,
    ): CancelablePromise<ImpersonationInfoViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/impersonate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}