import { UseQueryResult, useQuery } from '@tanstack/react-query';

import {
    NotificationsResultViewModel,
    NotificationsService,
} from '../../services';

import { QueryKeys } from '../../domain/QueryKeys';

import { useAuth } from '../useAuth';

export function useAlerts(): UseQueryResult<NotificationsResultViewModel> {
    const { makeRequest } = useAuth();
    return useQuery([QueryKeys.useAlerts], () =>
        makeRequest(NotificationsService.alerts)
    );
}
