import React, { FC, MouseEvent, ReactNode, cloneElement } from 'react';

import { Tab as ReactTab } from '@headlessui/react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import './Tabs.scss';

export const TabList: FC<{ children?: ReactNode }> = ({ children }) => {
    return (
        <ReactTab.List className="chw-tab-list" data-testid="tablist">
            {children}
        </ReactTab.List>
    );
};

export const TabPanel: FC<{ className?: string; children?: ReactNode }> = ({
    className,
    children,
}) => <ReactTab.Panels className={className}>{children}</ReactTab.Panels>;

type TabPanProps = {
    aside?: React.ReactNode;
    children?: ReactNode;
};

export const TabPan: FC<TabPanProps> = ({ children, aside }) => {
    const tabContentClass = classNames('tab-content', {
        [`tab-content--full-width`]: !aside,
    });
    return (
        <ReactTab.Panel>
            <div className={tabContentClass}>
                <div className="tab-content__body">{children}</div>
                {aside && <aside className="tab-content__aside">{aside}</aside>}
            </div>
        </ReactTab.Panel>
    );
};

type TabProps = {
    testId?: string;
    as?: 'a' | 'button';
    icon?: React.JSX.Element;
    children?: ReactNode;
};

type AnchorTabProps = {
    tab: string;
    searchParams?: Record<string, string>;
    onClick?: (e: MouseEvent) => void;
} & TabProps;

export const Tab: FC<TabProps> = ({ children, testId, icon, ...props }) => (
    <ReactTab className="chw-tab-button" {...props} data-testid={testId}>
        <div className="chw-tab-button__title">
            {icon && <span className="chw-tab-button__icon">{icon}</span>}
            {children}
        </div>
    </ReactTab>
);

export const AnchorTab: FC<AnchorTabProps> = ({
    children,
    testId,
    searchParams,
    tab,
    onClick,
    icon,
}) => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const urlSearchParams = new URLSearchParams(search);

    for (const key in searchParams) {
        urlSearchParams.set(key, searchParams[key]);
    }

    urlSearchParams.set('tab', tab);

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        navigate({
            pathname,
            search: urlSearchParams.toString(),
        });

        if (onClick) {
            onClick(e);
        }
    };

    return cloneElement(<Tab />, {
        testId,
        href: `${pathname}?${urlSearchParams.toString()}`,
        children,
        icon,
        as: 'a',
        onClick: handleClick,
    });
};

interface TabsProps {
    defaultIndex?: number;
    onChangeTab?(tabIndex: number): void;
    selectedIndex?: number;
    children?: ReactNode;
}

export const Tabs: FC<TabsProps> = ({
    children,
    onChangeTab,
    defaultIndex,
    selectedIndex,
}) => (
    <ReactTab.Group
        selectedIndex={selectedIndex}
        onChange={onChangeTab}
        defaultIndex={defaultIndex}
    >
        {children}
    </ReactTab.Group>
);
