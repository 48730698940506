import {
    ChangeEvent,
    FC,
    FormEvent,
    useEffect,
    useMemo,
    useState,
} from 'react';

import isEmail from 'validator/lib/isEmail';

import { Button } from '../Button/Button';
import { TextInput } from '../TextInput/TextInput';
import './ImpersonationModalContent.scss';
import content from './content';

export type ImpersonationModalContentProps = {
    isImpersonating: boolean;
    userName: string;
    onFormSubmit: (inputValue: string) => void;
    onStopImpersonating: () => void;
    serverError?: string;
};

export const ImpersonationModalContent: FC<ImpersonationModalContentProps> = ({
    isImpersonating,
    userName,
    onFormSubmit,
    onStopImpersonating,
    serverError,
}) => {
    const [inputValue, setInputValue] = useState({
        value: userName,
        isDirty: false,
    });
    const [inputError, setInputError] = useState('');

    useEffect(() => {
        const isValid = isEmail(inputValue.value);

        if (isValid || !inputValue.isDirty) {
            setInputError('');
        } else {
            setInputError('Please enter a valid email');
        }
    }, [inputValue.value]);

    useEffect(() => setInputError(serverError || ''), [serverError]);

    const submitDisabled = useMemo(
        () => inputError !== '' || isImpersonating || inputValue.value === '',
        [isImpersonating, inputValue.value, inputError]
    );

    const onUserInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue({ value, isDirty: true });
    };

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onFormSubmit(inputValue.value);
    };

    return (
        <div className="impersonation-modal-content">
            {isImpersonating && (
                <div
                    className="impersonation-modal-content__status"
                    data-testid="impersonation-status"
                >
                    <h4 className="impersonation-modal-content__status-title">
                        {content.statusLabel} <b>{userName}</b>
                    </h4>

                    <Button
                        variant="outline"
                        theme="light"
                        size="small"
                        onClick={onStopImpersonating}
                        testId="impersonation-status__stop-btn"
                    >
                        {content.stopImpersonatingBtn}
                    </Button>
                </div>
            )}
            <form
                onSubmit={handleFormSubmit}
                data-testid="impersonation-form"
                aria-label="impersonation-form"
            >
                <TextInput
                    name="userEmailInput"
                    id="userEmailInput"
                    label={content.impersonationForm.userInputLabel}
                    onChange={onUserInputChange}
                    value={inputValue.value}
                    disabled={isImpersonating}
                    error={inputError}
                    theme="light"
                    type="text"
                />
                <Button
                    type="submit"
                    disabled={submitDisabled}
                    theme="light"
                    testId="impersonation-form__submit-btn"
                >
                    {content.impersonationForm.submitBtn}
                </Button>
            </form>
        </div>
    );
};
