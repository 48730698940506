import { FC, ReactNode, useContext } from 'react';

import classNames from 'classnames';

import { MobileMenuContext } from '../../../../../context/MobileMenuContext';

import MenuIcon from '../../../../Icons/MenuIcon';
import './BurgerMenu.scss';

type BurgerMenuProps = {
    children: ReactNode;
};

export const BurgerMenu: FC<BurgerMenuProps> = ({ children }) => {
    const { activatedMobileMenu, onToggleMobileMenu } =
        useContext(MobileMenuContext);

    const onClickBurger = () => {
        if (activatedMobileMenu === 'burger') {
            onToggleMobileMenu(undefined);
        } else {
            onToggleMobileMenu('burger');
        }
    };

    return (
        <div className="burger-menu">
            <button
                aria-label="main navigation"
                className="burger-menu-trigger"
                onClick={onClickBurger}
            >
                <MenuIcon />
            </button>
            <div
                className={classNames('burger-menu-container', {
                    'is-open': activatedMobileMenu === 'burger',
                })}
                id="mobile-nav"
            >
                <div className="burger-menu-container__inner">
                    <div className="burger-menu-label">Navigation</div>
                    {children}
                </div>
            </div>
        </div>
    );
};
