import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { ApiError, UserService } from '../../services';

import { QueryKeys } from '../../domain/QueryKeys';

import { useAuth } from '../useAuth';

export const useUserCookie = ({
    enabled,
    forceLogin,
}: {
    enabled: boolean;
    forceLogin?: string;
}): UseQueryResult<string, ApiError> => {
    const force = !!forceLogin;
    const { makeRequest } = useAuth();

    return useQuery(
        [QueryKeys.useGetCookie, forceLogin],
        async () => {
            const request = await makeRequest<string>(() =>
                UserService.getCookie(force)
            );
            return request ?? null;
        },
        {
            enabled, // make request only if we have a valid auth token
            retry: false,
            staleTime: Infinity, // only make request once per session
        }
    );
};
