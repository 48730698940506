import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { MediaService } from '../../services';

export const MediaHandler: React.FC = () => {
    const params = useParams();
    const mediaId = params['*'] as string;

    useEffect(() => {
        MediaService.getMedia(mediaId).then((response) => {
            window.location.replace(response);
        });
    }, []);

    return null;
};
