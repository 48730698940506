/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserTour } from '../models/UserTour';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeaturesService {

    /**
     * @param tourName
     * @returns boolean Success
     * @throws ApiError
     */
    public static getTour(
        tourName?: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Features/GetTour',
            query: {
                'tourName': tourName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param tourName
     * @returns void
     * @throws ApiError
     */
    public static setTour(
        tourName?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Features/SetTour',
            query: {
                'tourName': tourName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns UserTour Success
     * @throws ApiError
     */
    public static getTours(): CancelablePromise<Array<UserTour>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Features/GetTours',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}