import React, { FC, ReactNode } from 'react';

import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';

import CloseIcon from '../Icons/CloseIcon';
import './Popover.scss';

export interface PopoverProps {
    className?: string;
    buttonText: ReactNode;
    panelContent: string | React.JSX.Element;
    testId: string;
}
export const PopoverComponent: FC<PopoverProps> = ({
    className,
    buttonText,
    panelContent,
    testId,
}) => {
    const popoverClass = classNames('chw-popover', className);
    return (
        <Popover className={popoverClass}>
            <Popover.Button className="chw-popover__button">
                <span data-testid={`${testId}__button`}>{buttonText}</span>
            </Popover.Button>
            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Popover.Panel className="chw-popover__panel">
                    <div
                        className="chw-popover__panel-inner"
                        data-testid={`${testId}__panel`}
                    >
                        <Popover.Button
                            className="chw-popover__close-btn"
                            title="Close"
                        >
                            <span>Close</span>
                            <CloseIcon />
                        </Popover.Button>
                        {panelContent}
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};
